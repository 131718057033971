import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Tag, Row, Col, Button, Tooltip } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import {
  statusTagColor,
  eventDateFormat,
} from "../../../../../shared/utils/utils";

import TwilightIcon from "../../../../../content/icons/Twilight.svg";

import "../styles.scss";
import CardAvatar from "./CardAvatar";
import PreviewMedia from "./PreviewMedia";

const CardItem = ({
  shoot,
  setSelectedShoot,
  setDrawerVisible,
  clientProfile,
}) => {
  const history = useHistory();

  const [photoCount, setPhotoCount] = useState(0);

  useEffect(() => {
    if (shoot) {
      setPhotoCount(shoot?.media?.photos?.gallery?.length > 0);
    }
  }, [shoot]); // eslint-disable-line react-hooks/exhaustive-deps

  const photos = shoot?.media?.photos;

  const handleOpenDrawer = () => {
    setSelectedShoot(shoot);
    setDrawerVisible(true);
  };

  return (
    <div
      span={24}
      className="inner-card"
      style={{ background: "#fafafa", border: "solid 1px #f1f1f1" }}
    >
      <Row justify="space-between">
        <Col flex="auto">
          <span className="street">
            {shoot?.address?.street?.replace("null", "")}{" "}
          </span>
          <span className="city">
            {shoot?.address?.city}, {shoot?.address?.state}
            {shoot?.address?.zip}
          </span>
          <div className="mt-2">
            <Tag className="float-right" color={statusTagColor(shoot?.status)}>
              {shoot?.status}
            </Tag>
            <span className="mr-2">
              <CalendarOutlined />{" "}
              <span>
                {shoot?.events?.start &&
                  !shoot?.flexible &&
                  eventDateFormat(shoot?.events?.start, shoot?.events?.end)}
                {!shoot?.events?.start && "TBD"}
                {shoot?.events?.start &&
                  shoot?.flexible &&
                  moment.utc(shoot?.events?.start).format("MM/DD/YY")}

                {shoot?.flexible && (
                  <Tag className="ml-2" color="magenta">
                    Flex
                  </Tag>
                )}
              </span>
              {/* {shoot?.events?.start
                ? eventDateFormat(shoot?.events?.start, shoot?.events?.end)
                : "TBD"} */}
              {shoot?.reqs?.includes("T") && (
                <Tooltip placement="topRight" title="Twilight Timeslot">
                  <span className="ml-2">
                    <img src={TwilightIcon} alt="Twilight" />
                  </span>
                </Tooltip>
              )}
            </span>
          </div>
        </Col>
      </Row>
      <div className="mt-3">
        <PreviewMedia
          clientProfile={clientProfile}
          shoot={shoot}
          handleOpenDrawer={handleOpenDrawer}
          history={history}
          photos={photos}
          photoCount={photoCount}
        />
      </div>

      <hr />
      <Row>
        <CardAvatar
          name={`${shoot?.client_name_first} ${shoot?.client_name_last}`}
          title={shoot?.client_role}
        />
        <Col>
          <Button
            type="primary"
            onClick={() => history.push(`/admin/shoots/${shoot._id}`)}
          >
            Details
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CardItem;
