import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Table, Button, Modal, Row, Col, Input, Typography, Tag,message } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import Loading from "../../../shared/loading/Loading.js";
import moment from "moment";
import {
  fetchCampaigns,
  createCampaign,
  updateCampaign,
  removeCampaign,
} from "../../../redux/action/campaignAction";
import CampaignForm from "./CampaignForm";
import queryString from "query-string";
import NumberFormat from "react-number-format";
import { fetchOfferings } from "../../../redux/action/offeringAction.js";
const { Text } = Typography;

const Campaigns = ({
  handleCurrentPath,
  fetchCampaigns,
  createCampaign,
  updateCampaign,
  removeCampaign,
  campaigns,
  fetchOfferings,
  offerings,
  auth: { user },
}) => {
  const [state, setState] = useState({
    title: "Add Campaign",
    mode: "Add",
    modalVisiblity: false,
    initVal: null,

  });
  useEffect(() => {
    fetchOfferings();
  }, [fetchOfferings]);

  const [selectedOfferings, setSelectedOfferings] = useState([]);

  const { mode, modalVisiblity, initVal } = state;
  const { confirm } = Modal;
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const formInitValue = {
    description: "",
    code: "",
    activation_date: moment().format("YYYY-MM-DD"),
    expiration_date: null,
    amount: 0,
    active: true,
    single_use: false,
  };

  
  const [loading, setLoading] = useState(false);  

  useEffect(() => {
    handleCurrentPath("Campaigns", "campaigns");
    fetchCampaigns({ search: "", skip: 1, limit: 10 });

    setLoading(false);

  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCampaigns = campaigns.campaigns.filter(campaign => 
    campaign && campaign.name && campaign.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [tableOpt, setOperation] = useState({
    search: "",
    limit: 10,
    skip: 1,
  });

  const { search, skip, limit } = tableOpt;


  const handleCancel = () => {
    setState({ ...state, modalVisiblity: false });
  };

  const submitHandler = async (type,values, body) => {
    try {
      let res;
      const campaignData = {
      ...values,
      offering:values?.offering, 
    };
      if (type === "Add") {
        res = await createCampaign(campaignData);
        if (res.status === 200||res.status === 201) {
          fetchCampaigns({ search, skip: 1, limit });
        }
      } else {
        res = await updateCampaign(campaignData._id, campaignData);
        if (res.status === 200||res.status === 201) {
          fetchCampaigns({ search, skip, limit });
        }
      }
      handleCancel();
      return res;
    } catch (error) {
      message.error("Error occurred while processing campaign");
      console.error(error);
    }
  };

  useEffect(() => {
      window.onpopstate = (e) => {
        let skip = 1;
        const value = queryString.parse(history.location.search);
        if (value.page) {
          skip = parseInt(value.page);
          setOperation({ ...tableOpt, skip });
        } else {
          setOperation({ ...tableOpt, skip });
        }
        fetchCampaigns();
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    const searchHandler = ({ target }) => {
      const { value } = target;
      setSearchTerm(value);
      setOperation({
        ...tableOpt,
        skip: 1,
        search: value,
      });
      fetchCampaigns({
        search: value,
        skip: 1,
        limit,
      });
    };

  const handleTableChange = (pagination) => {
    const params = new URLSearchParams();
    params.append("page", pagination.current);
    history.push({ search: params.toString() });

    setOperation({
      ...tableOpt,
      skip: pagination.current,
      limit: pagination.pageSize,
    });
    fetchCampaigns({ search, skip: pagination.current, limit: pagination.pageSize });
  };

  const clickHandler = async (mode, initVal) => {
    setState({
      mode,
      initVal,
      title: `${mode} Campaigns`,
      modalVisiblity: true,
    });
    if (mode === "Edit") {
      await fetchOfferings(); 
      setSelectedOfferings(initVal?.offerings?.map(o => o._id));
    } else {
      setSelectedOfferings([]);
    }
  };

  const onConfirmRemove = (campaign) => {
    Modal.confirm({
      title: "Are you sure?",
      content: `Are you sure you want to delete campaign ${campaign?.name}?`,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        removeCampaign(campaign?._id);
      },
      onCancel() {
        // handleOk();
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Campaign Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Campaign Type",
      dataIndex: "campaignType",
      key: "campaignType",
      render: (_, record) => record?.campaignType || "N/A",
    },
    {
    title: "Conversions",
      dataIndex: "conversions",
      key: "clicks",
      render: (_, record) => record?.ad?.actions?.primary?.clicks,
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "views",
    },
    {
      title: "Active Status",
      dataIndex: "active",
      key: "active",
      render: (active) => (
        <Tag color={active ? "green" : "red"}>{active ? "Active" : "Inactive"}</Tag>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate) => moment(startDate).format("YYYY-MM-DD"),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate) => (endDate ? moment(endDate).format("YYYY-MM-DD") : "No End Date"),
    },
    {
      key: "actions",
      render: (record) => (
        <span>
          <Button
            icon={<EditOutlined />}
            onClick={() => clickHandler("Edit", record)}
            style={{ margin: "0 5px 5px 0" }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => onConfirmRemove(record)}
          />
        </span>
      ),
    },
  ];

  return (
    <Card style={{ margin: "20px" }}>
      {loading ? (
        <div className="loading-container">
          <Loading />
        </div>
      ) : (
        <div>
          <Row>
            <Col span={16}>
              <h2>Campaigns</h2>
            </Col>
            <Col span={4}>
            <Input.Search
                placeholder="Search by campaign name"
                onChange={handleSearchChange}
              />
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                style={{ float: "right" }}
                onClick={() => clickHandler("Add", formInitValue)}
              >
                <PlusOutlined /> Add a Campaign
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={filteredCampaigns}
            rowKey={(record) => record._id}
            pagination={{
              current: skip,
              pageSize: limit,
              total: filteredCampaigns?.count,
            }}
            onChange={handleTableChange}
            scroll={{ x: 500 }}
          />
        </div>
      )}
      <Modal
        title={state.title}
        onCancel={handleCancel}
        visible={modalVisiblity}
        footer={null}
        destroyOnClose
      >
        <CampaignForm
          initVal={initVal}
          mode={mode}
          close={handleCancel}
          submitHandler={(values) => submitHandler(mode, values)}
          selectedOfferings={selectedOfferings}
          setSelectedOfferings={setSelectedOfferings}
          offerings={offerings}

        />
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  campaigns: state.campaign,
  offerings:state.offering,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchCampaigns,
  createCampaign,
  updateCampaign,
  removeCampaign,
  fetchOfferings,
})(Campaigns);
