import React, { useState, useCallback } from "react";
import { Col, Input, Alert } from "antd";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { setKey, fromAddress } from "react-geocode";
import { GoogleMap, MarkerF } from "@react-google-maps/api";

const AddressAutocomplete = ({ formData, setFormData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasAddressBeenSet, setHasAddressBeenSet] = useState(false);

  const handleOnSelect = async (address, placeId) => {
    setIsLoading(true);
    setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

    const results = await geocodeByPlaceId(placeId);
    const addressObject = results[0];

    let street, city, state, zip;
    addressObject.address_components.forEach((component) => {
      if (component.types.includes("street_number")) {
        street = component.long_name;
      } else if (component.types.includes("route")) {
        street = street
          ? `${street} ${component.long_name}`
          : component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.short_name;
      } else if (component.types.includes("postal_code")) {
        zip = component.long_name;
      }
    });
    const fullAddress = `${street},${city},${state}${zip}`;
    const response = await fromAddress(fullAddress);
    if (response) {
      const { lat, lng } = response.results[0].geometry.location;
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: {
          ...prevFormData.address,
          street,
          city,
          state,
          zip,
          geometry: {
            ...prevFormData.address.geometry,
            coordinates: [lng, lat],
          },
          uniqueAddressId: placeId,
        },
      }));
    }
    setIsLoading(false);
  };
  
  const handleManualAddressChange = async () => {
    if(hasAddressBeenSet) return;
    setIsLoading(true);
    setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    const manualAddress = `
      ${formData?.address?.street}, ${formData?.address?.city}, 
      ${formData?.address?.state} ${formData?.address?.zip}`.trim();
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${
      encodeURIComponent(manualAddress)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    );
    if(response){
      const data = await response.json();
      const results = await geocodeByPlaceId(data.results[0].place_id);
      const addressObject = results[0];

      let street, city, state, zip;
      addressObject.address_components.forEach((component) => {
        if (component.types.includes("street_number")) {
          street = component.long_name;
        } else if (component.types.includes("route")) {
          street = street
            ? `${street} ${component.long_name}`
            : component.long_name;
        } else if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        } else if (component.types.includes("postal_code")) {
          zip = component.long_name;
        }
      });
      const fullAddress = `${street},${city},${state}${zip}`;
      const AddressResponse = await fromAddress(fullAddress);
      if (AddressResponse) {
        const { lat, lng } = AddressResponse.results[0].geometry.location;
        console.log(lat, lng);
        setFormData((prevFormData) => ({
          ...prevFormData,
          address: {
            ...prevFormData.address,
            street,
            city,
            state,
            zip,
            geometry: {
              ...prevFormData.address.geometry,
              coordinates: [lng, lat],
            },
            uniqueAddressId: data.results[0].place_id,
          },
        }));
      }
      setHasAddressBeenSet(true);
      setIsLoading(false);
    }
  };
  let center = {
    lng: formData?.address?.geometry?.coordinates[0]
      ? parseFloat(formData.address.geometry.coordinates[0])
      : null,
    lat: formData?.address?.geometry?.coordinates[1]
      ? parseFloat(formData.address.geometry.coordinates[1])
      : null,
  };

  const onMarkerDragEnd = useCallback(
    async (event) => {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: newPosition }, (results, status) => {
        if (status === "OK" && results[0]) {
          const addressComponents = results[0].address_components;

          let street, city, state, zip;

          addressComponents.forEach((component) => {
            if (
              component.types.includes("street_address") ||
              component.types.includes("route")
            ) {
              street = component.long_name;
            }
            if (component.types.includes("locality")) {
              city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
              state = component.short_name;
            }
            if (component.types.includes("postal_code")) {
              zip = component.long_name;
            }
          });
          setFormData((prevFormData) => ({
            ...prevFormData,
            address: {
              ...prevFormData.address,
              street,
              city,
              state,
              zip,
              geometry: {
                ...prevFormData.address.geometry,
                coordinates: [newPosition.lng, newPosition.lat],
              },
            },
          }));
        }
      });
    },
    [setFormData]
  );

  return (
    <>
      <Col className="mt-4" span={24}>
        <strong>ADDRESS OF PROPERTY BEING PHOTOGRAPHED</strong>
      </Col>
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <div className="form-group">
          <label>Street Address</label>
          <PlacesAutocomplete
            value={formData?.address?.street}
            onChange={(street_address) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                address: {
                  ...prevFormData.address,
                  street: street_address,
                },
              }))
            }
            onSelect={handleOnSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <Input
                  {...getInputProps({
                    placeholder: "Start typing the address...",
                    className: "newTextBox",
                    name: "address.street",
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const style = suggestion.active
                      ? {
                          fontWeight: "600",
                          cursor: "pointer",
                          backgroundColor: "#f0f0f0",
                          padding: "10px",
                          borderRadius: "4px",
                          marginBottom: "5px",
                        }
                      : {
                          cursor: "pointer",
                          padding: "10px",
                          borderRadius: "4px",
                          marginBottom: "5px",
                          color: "#555",
                        };
                    return (
                      <div
                        className="auto-suggestions"
                        key={suggestion.placeId}
                      >
                        <div
                          {...getSuggestionItemProps(suggestion, { style })}
                          style={{
                            ...style,
                            transition: "background-color 0.2s ease",
                          }}
                        >
                          {suggestion.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <div className="form-group mt10"></div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <div className="form-group">
          <label>Apt/Unit (Optional)</label>
          <Input
            className="newTextBox"
            placeholder="e.g. 227"
            name="address.apt"
            value={formData.address.apt}
            onChange={(e) => {
              e.persist();
              setFormData((prevFormData) => ({
                ...prevFormData,
                address: {
                  ...prevFormData.address,
                  apt: e.target.value,
                },
              }));
            }}
            disabled={isLoading}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <div className="form-group">
          <label>City</label>
          <Input
            className="newTextBox"
            value={formData.address.city}
            name="address.city"
            onChange={(e) => {
              e.persist();
              setFormData((prevFormData) => ({
                ...prevFormData,
                address: {
                  ...prevFormData.address,
                  city: e.target.value,
                },
              }));
            }}
            disabled={isLoading}
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <div className="form-group">
          <label>State</label>
          <Input
            value={formData.address.state}
            className="newTextBox"
            name="address.state"
            onChange={(e) => {
              e.persist();
              setFormData((prevFormData) => ({
                ...prevFormData,
                address: {
                  ...prevFormData.address,
                  state: e.target.value,
                },
              }));
            }}
            disabled={isLoading}
            placeholder="State"
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
        <div className="form-group">
          <label>Zip</label>
          <Input
            className="newTextBox"
            name="address.zip"
            value={formData.address.zip}
            onChange={(e) => {
              e.persist();
              setFormData((prevFormData) => ({
                ...prevFormData,
                address: {
                  ...prevFormData.address,
                  zip: e.target.value,
                },
              }));
            }}
            onBlur={handleManualAddressChange}
            disabled={isLoading}
          />
        </div>
      </Col>
      {center.lat && center.lng && (
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Alert
            message=" If needed, drag the pin below to update the location."
            type="info"
            showIcon
          />
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "400px",
            }}
            center={center}
            zoom={17}
            options={{
              mapTypeId: "hybrid",
            }}
          >
            <MarkerF
              position={center}
              draggable={true}
              onDragEnd={onMarkerDragEnd}
            />
          </GoogleMap>
        </Col>
      )}
    </>
  );
};

export default AddressAutocomplete;
