export const filtersObj = [
  {
    text: "Aerial Video (np)",
    children: [
      { text: "Residential", value: "vA" },
      { text: "Multifamily", value: "MvA" },
      { text: "Commercial", value: "CvA" },
      { text: "Additional", value: "AvA" },
    ],
  },
  {
    text: "Aerial Video (p)",
    children: [
      { text: "Residential", value: "vAp" },
      { text: "Multifamily", value: "MvAp" },
      { text: "Commercial", value: "CvAp" },
      { text: "Additional", value: "AvAp" },
    ],
  },
  {
    text: "Deluxe Photo Package",
    children: [{ text: "Residential", value: "DLX" }],
  },
  {
    text: "Dollhouse 3D Tour",
    children: [
      { text: "Residential", value: "VR" },
      { text: "Multifamily", value: "MVR" },
      { text: "Commercial", value: "CVR" },
      { text: "Additional", value: "AVR" },
    ],
  },
  {
    text: "Dollhouse Floorplan",
    children: [
      { text: "Residential", value: "FLP" },
      { text: "Multifamily", value: "MFLP" },
      { text: "Commercial", value: "CFLP" },
      { text: "Additional", value: "AFLP" },
    ],
  },
  {
    text: "Flyer Design",
    children: [
      { text: "Residential", value: "F" },
      { text: "Multifamily", value: "MF" },
      { text: "Commercial", value: "CF" },
      { text: "Additional", value: "AF" },
    ],
  },
  {
    text: "Photo - 4-hour Turnaround",
    children: [
      { text: "Residential", value: "4HR" },
      { text: "Multifamily", value: "M4HR" },
      { text: "Commercial", value: "C4HR" },
      { text: "Additional", value: "A4HR" },
    ],
  },
  {
    text: "Photo - Same Day by 9:00p",
    children: [
      { text: "Residential", value: "SD" },
      { text: "Multifamily", value: "MSD" },
      { text: "Commercial", value: "CSD" },
      { text: "Additional", value: "ASD" },
    ],
  },
  {
    text: "Reschedule Fee",
    children: [
      { text: "Residential", value: "RF" },
      { text: "Multifamily", value: "MRF" },
      { text: "Commercial", value: "CRF" },
      { text: "Additional", value: "ARF" },
    ],
  },
  {
    text: "Reschedule/Cancelation Fee",
    children: [
      { text: "Residential", value: "CF" },
      { text: "Multifamily", value: "MCF" },
      { text: "Commercial", value: "CCF" },
      { text: "Additional", value: "ACF" },
    ],
  },
  {
    text: "Reschedule/Cancelation Fee (within 12 hours)",
    children: [
      { text: "Residential", value: "CF12" },
      { text: "Multifamily", value: "MCF12" },
      { text: "Commercial", value: "CCF12" },
      { text: "Additional", value: "ACF12" },
    ],
  },
  {
    text: "Reschedule/Cancelation Fee (within 24 hours)",
    children: [
      { text: "Residential", value: "CF24" },
      { text: "Multifamily", value: "MCF24" },
      { text: "Commercial", value: "CCF24" },
      { text: "Additional", value: "ACF24" },
    ],
  },
  {
    text: "Social Media Video",
    children: [
      { text: "Residential", value: "SMV" },
      { text: "Residential", value: "SMV30" },
      { text: "Multifamily", value: "MSMV" },
      { text: "Commercial", value: "CSMV" },
      { text: "Additional", value: "ASMV" },
    ],
  },
  {
    text: "Subdivision Amenity Package",
    children: [
      { text: "Residential", value: "SP" },
      { text: "Multifamily", value: "MSP" },
      { text: "Commercial", value: "CSP" },
      { text: "Additional", value: "ASP" },
    ],
  },
  {
    text: "Trip Charge",
    value: "TC",
  },
  {
    text: "Twilight Timeslot",
    children: [
      { text: "Residential", value: "T" },
      { text: "Multifamily", value: "MT" },
      { text: "Commercial", value: "CT" },
      { text: "Additional", value: "AT" },
    ],
  },
  {
    text: "Video - Next Day by 12:00p",
    children: [
      { text: "Residential", value: "1D12!" },
      { text: "Multifamily", value: "M1D12!" },
      { text: "Commercial", value: "C1D12!" },
      { text: "Additional", value: "A1D12!" },
    ],
  },
  {
    text: "Video - Next Day by 9:00p",
    children: [
      { text: "Residential", value: "1D9" },
      { text: "Multifamily", value: "M1D9" },
      { text: "Commercial", value: "C1D9" },
      { text: "Additional", value: "A1D9" },
    ],
  },
  {
    text: "Walkthrough Video",
    children: [
      { text: "Residential", value: "vW" },
      { text: "Multifamily", value: "MvW" },
      { text: "Commercial", value: "CvW" },
      { text: "Additional", value: "AvW" },
    ],
  },
  {
    text: "Walkthrough Video w/ Aerial (np)",
    children: [
      { text: "Residential", value: "vWA" },
      { text: "Multifamily", value: "MvWA" },
      { text: "Commercial", value: "CvWA" },
      { text: "Additional", value: "AvWA" },
    ],
  },
  {
    text: "Walkthrough Video w/ Aerial (p)",
    children: [
      { text: "Residential", value: "vWAp" },
      { text: "Multifamily", value: "MvWAp" },
      { text: "Commercial", value: "CvWAp" },
      { text: "Additional", value: "AvWAp" },
    ],
  },
  {
    text: "Youtube Slideshow",
    children: [
      { text: "Residential", value: "YT" },
      { text: "Multifamily", value: "MYT" },
      { text: "Commercial", value: "CYT" },
      { text: "Additional", value: "AYT" },
    ],
  },
];
