import React, { useState } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Spin,
  Button,
  message,
  Switch,
  DatePicker,
  Select,
} from "antd";
import { Input, Form } from "formik-antd";
import { Formik } from "formik";

const { Option } = Select;

const CouponForm = ({ mode, initVal, close, submitHandler, offerings }) => {
  const [state, setState] = useState({ loader: false });
  const { loader } = state;
  const [isActive, setIsActive] = useState(initVal?.active);
  const [isSingleUse, setIsSingleUse] = useState(initVal?.single_use);
  const [activationDate, setActivationDate] = useState(
    initVal?.activation_date ? moment(initVal?.activation_date) : null
  );
  const [expirationDate, setExpirationDate] = useState(
    initVal?.expiration_date ? moment(initVal?.expiration_date) : null
  );
  const handleSwitchChange = (val, checked) => {
    if (val === "active") {
      setIsActive(checked);
    } else if (val === "single_use") {
      setIsSingleUse(checked);
    }
  };

  const handleSelectOffering = (value, setFieldValue) => {
    const selectedOffering = offerings.find(
      (offering) => offering._id === value
    );
    if (selectedOffering) {
      setFieldValue("offeringId", value);
      setFieldValue("amount", selectedOffering.price);
    };
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initVal}
      onSubmit={async (values) => {
        setState({ ...state, loader: true });
        const res = await submitHandler({
          ...values,
          single_use: isSingleUse,
          activation_date: activationDate,
          expiration_date: expirationDate,
          active: isActive,
        });
        if (res.status === 200) {
          mode === "Add"
            ? message.success("Coupon created successfully")
            : message.success("Coupon updated successfully!");
        } else {
          message.error("Oops! Error occured while updating credit.");
        }
        setState({ ...state, loader: false });
        close(res.data);
      }}
      render={({ handleSubmit, setFieldValue }) => (
        <div className="form-container">
          <Spin spinning={loader}>
            <Form layout="vertical">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label="Coupon Code"
                    name="code"
                    className="form-group"
                  >
                    <Input name="code" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Amount"
                    name="amount"
                    className="form-group"
                  >
                    <Input type="number" name="amount" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Description"
                    name="description"
                    className="form-group"
                  >
                    <Input name="description" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div>
                    <strong> Is this coupon specific to an offering?</strong>
                  </div>
                  <Select
                    placeholder="Select an offering"
                    onChange={(value) => handleSelectOffering(value, setFieldValue)}
                    style={{ width: "100%" }}
                  >
                    {offerings.map((offering) => (
                      <Option key={offering._id} value={offering._id}>
                        {offering.description}{" - "}${offering.price}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={24}>
                  <Select
                    name="platform"
                    placeholder="Select a platform"
                    onChange={(value) => setFieldValue("platform", value)}
                  >
                    <Option value="all">All</Option>
                    <Option value="web">Web</Option>
                    <Option value="mobile">Mobile</Option>
                  </Select>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div>
                    <strong>Activation Date</strong>
                  </div>
                  <DatePicker
                    name="activation_date"
                    defaultValue={activationDate ? activationDate : moment()}
                    onChange={(value) =>
                      setActivationDate(moment(value).format())
                    }
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div>
                    <strong>Expiration Date</strong>
                  </div>

                  <DatePicker
                    name="expiration_date"
                    defaultValue={expirationDate ? expirationDate : null}
                    onChange={(value) =>
                      setExpirationDate(moment(value).format())
                    }
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Active"
                    name="active"
                    className="form-group"
                  >
                    {isActive ? "Yes" : "No"}{" "}
                    <Switch
                      checked={isActive}
                      onChange={(checked) =>
                        handleSwitchChange("active", checked)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="One time use?"
                    name="single_use"
                    className="form-group"
                  >
                    {isSingleUse ? "Yes" : "No"}{" "}
                    <Switch
                      checked={isSingleUse}
                      onChange={(checked) =>
                        handleSwitchChange("single_use", checked)
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    style={{ marginTop: "10px" }}
                  >
                    Save
                  </Button>
                  <Button type="link" onClick={close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      )}
    />
  );
};

export default CouponForm;
