import {
  WORKED_BEFORE,
  BOOKING_FORM,
  DOLL_HOUSE,
  ZILLOW_SHOWCASE,
  INVOICE,
  PHOTOS,
  VIDEOS,
  EVENTS,
  ADDRESS,
  CLIENT,
  TRIPCHARGE,
  CLEAR_ALL,
  CLEAR_FORM,
  INITIALSHOOTSTATE,
} from "../action/shoot/shootConstants";
import { UPDATE_BOOKSHOOT_STATE, UPDATE_INVOICE_STATE } from "../action/types";

const initialState = {
  ...INITIALSHOOTSTATE,
};

const getLocalState = () =>
  localStorage.getItem("localbookShootReducer")
    ? JSON.parse(localStorage.getItem("localbookShootReducer"))
    : {};

const setLocalState = (data) => {
  localStorage.setItem(
    "localbookShootReducer",
    JSON.stringify({
      ...getLocalState(),
      ...data,
    })
  );
};

const bookShootReducer = (state = initialState, action) => {
  switch (action.type) {
    case WORKED_BEFORE:
      setLocalState({ new_client_bool: action.value });
      return {
        ...state,
        new_client_bool: action.value,
      };

    case CLIENT:
      const clnt = { ...state };
      Object.assign(clnt, action.value);
      return {
        ...clnt,
      };

    case BOOKING_FORM:
      localStorage.setItem("booking_form", JSON.stringify(action.value));
      setLocalState({ booking_form: action.value });
      return {
        ...state,
        booking_form: action.value,
      };

    case UPDATE_BOOKSHOOT_STATE:
      setLocalState({ ...action.payload });
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_INVOICE_STATE:
      setLocalState({ invoice: action.payload });
      return {
        ...state,
        invoice: action.payload,
      };

    case ADDRESS:
      setLocalState({ address: action.value });
      return {
        ...state,
        address: action.value,
      };

    case TRIPCHARGE:
      setLocalState({ trip_charge: action.value });
      return {
        ...state,
        trip_charge: action.value,
      };

    case DOLL_HOUSE:
      setLocalState({ dollhouse: action.value });
      return {
        ...state,
        dollhouse: action.value,
      };

    case ZILLOW_SHOWCASE:
      setLocalState ({zillowshowcase: action.value});
      return {
        ...state,
        zillowshowcase: action.value,
      };

    case INVOICE:
      setLocalState({
        invoice: action.value,
        reqs: action.value.products.map((off) => off.code),
      });
      return {
        ...state,
        invoice: action.value,
        reqs: action.value.products.map((off) => off.code),
      };

    case PHOTOS:
      setLocalState({
        photos: action.value,
      });
      return {
        ...state,
        photos: action.value,
      };

    case VIDEOS:
      setLocalState({
        video: action.value,
      });
      return {
        ...state,
        video: action.value,
      };

    case EVENTS:
      setLocalState({
        events: action.value,
      });
      return {
        ...state,
        events: action.value,
      };

    case CLEAR_FORM:
      return {
        ...INITIALSHOOTSTATE,
        invoice: state.invoice,
      };
    case CLEAR_ALL:
      return {
        ...INITIALSHOOTSTATE,
      };
    default:
      return state;
  }
};
export default bookShootReducer;
