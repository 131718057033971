import React, { useState } from "react";
import { Button, Popconfirm, message, Input } from "antd";
import { LikeOutlined } from "@ant-design/icons";
import { calculateTax } from "../../../shared/utils/utils";

const CompItemButton = ({ item, data, createActivity, updateInvoice, index }) => {
  const [compReason, setCompReason] = useState("None given.");

  const handleCompItem = async (id, desc, index) => {
    let products = data.products;
    let subtotal;
    const editedProducts = products.map((product, i) => {
      if (product._id === id && i === index) {
        product.orig_price = product.price;
        subtotal = data.subtotal - product.price;
        product.price = 0;
      }
      return product;
    });

    const tax = calculateTax(subtotal - (data.discount || 0));
    const total = subtotal + tax - (data.discount || 0);
    let balance = total.toFixed(2);
    if (balance <= 0) {
      balance = 0;
      data.paid = true;
    }

    const updatedData = {
      ...data,
      products: editedProducts,
      subtotal,
      tax,
      total,
      balance,
    };
    const res = await updateInvoice(data._id, updatedData);

    if (!res.error) {
      // Create activity
      createActivity(
        data.shoot,
        `Complementary service - ${desc}. Reason: ${compReason}.`
      );
      // Success message
      message.success(`${desc} comped. Invoice updated successfully.`);
    } else {
      message.error("Oops! Something went wrong.");
    }
  };

  const renderPopconfirmContent = (item) => {
    return (
      <div>
        <p>{`Are you sure you want to comp ${item.description}?`}</p>
        <div className="form-group">
          <label>Reason:</label>
          <Input
            onChange={(e) => setCompReason(e.target.value)}
            placeholder="e.g. No-fly zone"
            name="comp_reason"
          />
        </div>
      </div>
    );
  };

  return (
    <Popconfirm
      title={renderPopconfirmContent(item)}
      onConfirm={() => handleCompItem(item._id, item.description, index)}
      okText="Yes"
      cancelText="No"
    >
      <Button type="link" size="small">
        <LikeOutlined /> Comp
      </Button>
    </Popconfirm>
  );
};

export default CompItemButton;
