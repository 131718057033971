import React, { useState, useImperativeHandle, useEffect } from "react";
import {
  Select,
  Row,
  Col,
  Button,
  message,
  Typography,
  Input,
  Tag,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./Invoice.scss";
import AmenityModal from "./AmenityModal";
const { Option } = Select;
const { Search } = Input;
const InvoiceModal = React.forwardRef(
  (
    {
      auth,
      invoice,
      offerings,
      mode,
      updAmen,
      updSub,
      updShoot,
      updSubdivision,
      updAmenity,
      fetchShootbyHsn,
    },
    ref
  ) => {
    const [state, setState] = useState({
      newOfferingsIds: [],
      shoot: null,
      shootId: null,
      clientId: null,
      shoot_type: null,
      brokerageId: null,
      allOfferings: offerings,
      updatedOfferings: null,
      currentOfferings:
        mode === "add"
          ? []
          : invoice[0]
          ? invoice[0].products
          : invoice?.products,
      subtotal:
        mode === "add"
          ? 0
          : invoice[0]
          ? invoice[0].subtotal
          : invoice.subtotal,
      canceled: invoice.canceled,
      reschedule: invoice.reschedule,
    });
    const [amentityShow, setAmentityShow] = useState(false);
    const [foundShoot, setFoundShoot] = useState(null);

    useImperativeHandle(
      ref,
      () => ({
        getModalState: () => {
          return state;
        },
      }),
      [state]
    );
    const showAmenityModal = () => {
      setAmentityShow(!amentityShow);
    };
    function handleChange(value, key) {
      // if (key === "shootId") {
      //   const shoot = shoots.filter((sht) => sht._id === value)[0];
      //   setState({
      //     ...state,
      //     shoot,
      //     shootId: shoot._id,
      //     clientId: shoot.client_id,
      //     brokerageId: shoot.brokerage ? shoot.brokerage : null,
      //     newOfferingsIds: [],
      //     shoot_type: null,
      //     subtotal: 0,
      //   });
      //   setDisplayClient(false);
      // } else if (key === "clientId") {
      //   const client = clients.filter((clt) => clt._id === value)[0];
      //   clientDetails(client);
      //   setState({
      //     ...state,
      //     shoot: undefined,
      //     shootId: undefined,
      //     clientId: client._id,
      //     brokerageId: client.brokerage,
      //   });
      // } else
      if (key === "shoot_type") {
        setState({
          ...state,
          newOfferingsIds: [],
          subtotal: 0,
          shoot_type: value === undefined ? null : value,
        });
      } else {
        const offering = state.updatedOfferings.filter(
          (off) => off._id === value
        )[0];
        if (offering.code === "VR") {
          if (mode === "edit") {
            // Recalculate Dollhouse price
            let sqft = invoice.shoot.booking_form.sqft;
            let baseValueForDollHouse = 47;
            let updatedPrice = 0;
            if (sqft > 0) {
              updatedPrice =
                (((baseValueForDollHouse * (30 + (sqft / 1000) * 35)) / 60 +
                  49 +
                  (sqft / 250) * 5) /
                  sqft) *
                sqft;
            }

            const formattedPrice = parseFloat(updatedPrice.toFixed(2));

            offering.price = formattedPrice;
            // Update invoice.shoot.dollhouse.price
            if (invoice.shoot.dollhouse.price !== formattedPrice) {
              const updatedDollHouse = {
                ...invoice.shoot.dollhouse,
                price: formattedPrice,
              };
              updShoot(invoice.shoot._id, { dollhouse: updatedDollHouse });
            }
          } else {
            if (state.shootId) {
              // debugger;
              // const shoot = shoots.filter(
              //   (sht) => sht._id === state.shootId
              // )[0];
              offering.price = foundShoot?.dollhouse?.price;
            } else {
              message.error(
                "Dollhouse cannot be selected unless shoot is configured to it."
              );
              return;
            }
          }
        }
        const subtotal = state.subtotal + offering.price;
        setState({
          ...state,
          [key]: [...state[key], value],
          subtotal: subtotal,
        });
      }
    }
    const {
      currentOfferings,
      allOfferings,
      newOfferingsIds,
      shoot_type,
      shoot,
    } = state;

    useEffect(() => {
      let categorySelected =
        mode === "add"
          ? shoot_type
            ? shoot_type
            : shoot?.booking_form?.shoot_type
          : invoice?.shoot?.booking_form.shoot_type;

      if (categorySelected === "Multi-family") {
        categorySelected = "Multifamily";
      }
      let dollHousePrice =
        mode === "add"
          ? shoot?.dollhouse?.price
          : invoice?.shoot?.dollhouse?.price;
      let socialMediaPrice =
        mode === "add"
          ? shoot?.socialMediaPrice?.price
          : invoice?.shoot?.socialMedia?.price;
      let sqft =
        mode === "add"
          ? shoot?.booking_form?.sqft
          : invoice?.shoot?.booking_form.sqft;

      let newOfferings = [];
      allOfferings.map((off) => {
        if (off.category.includes(categorySelected)) {
          newOfferings.push({ ...off });
        }
      });
      const Residentialabove4kPhts = allOfferings?.filter(
        (off) => off.code === "4Kp"
      )[0];
      const Residentialabove4kVids = allOfferings?.filter(
        (off) => off.code === "4Kv"
      )[0];
      const Commercialabove4kPhts = allOfferings?.filter(
        (off) => off.code === "C4Kp"
      )[0];
      const Commercialabove4kVids = allOfferings?.filter(
        (off) => off.code === "C4Kpv"
      )[0];
      const Multifamilyabove4kPhts = allOfferings?.filter(
        (off) => off.code === "M4Kp"
      )[0];
      const Multifamilyabove4kVids = allOfferings?.filter(
        (off) => off.code === "M4Kv"
      )[0];
      newOfferings.map((off) => {
        if (
          off.category === "Residential - Interior/Exterior Photos" &&
          [
            "ie5",
            "ie8",
            "ie10",
            "ie15",
            "ie20",
            "ie25",
            "ie36",
            "ie42",
            "ie50",
            "ie75",
            "ie100",
          ].includes(off.code) &&
          off.active &&
          categorySelected === "Residential"
        ) {
          if (sqft && sqft >= 4000) {
            off.price += Residentialabove4kPhts.price;
          }
        } else if (
          off.category === "Commercial - Interior/Exterior Photos" &&
          off.active &&
          categorySelected === "Commercial"
        ) {
          if (sqft && sqft >= 4000) {
            off.price += Commercialabove4kPhts.price;
          }
        } else if (
          off.category === "Multifamily - Interior/Exterior Photos" &&
          off.active &&
          categorySelected === "Multifamily"
        ) {
          if (sqft && sqft >= 4000) {
            off.price += Multifamilyabove4kPhts.price;
          }
        } else if (
          off.category === "Multifamily - Drone Photos" &&
          categorySelected === "Multifamily" &&
          off.active
        ) {
        } else if (off.code === "vA" && categorySelected === "Residential") {
          if (sqft && sqft >= 4000) {
            off.price += Residentialabove4kVids.price;
          }
        } else if (off.code === "CvA" && categorySelected === "Commercial") {
          if (sqft && sqft >= 4000) {
            off.price += Commercialabove4kVids.price;
          }
        } else if (off.code === "MvA" && categorySelected === "Multifamily") {
          if (sqft && sqft >= 4000) {
            off.price += Multifamilyabove4kVids.price;
          }
        } else if (off.code === "vWA" && categorySelected === "Residential") {
          if (sqft && sqft >= 4000) {
            off.price += Residentialabove4kVids.price;
          }
        } else if (off.code === "CvWA" && categorySelected === "Commercial") {
          if (sqft && sqft >= 4000) {
            off.price += Commercialabove4kVids.price;
          }
        } else if (off.code === "MvWA" && categorySelected === "Multifamily") {
          if (sqft && sqft >= 4000) {
            off.price += Multifamilyabove4kVids.price;
          }
        } else if (off.code === "vCA" && categorySelected === "Residential") {
          if (sqft && sqft >= 4000) {
            off.price += Residentialabove4kVids.price;
          }
        } else if (off.code === "CvCA" && categorySelected === "Commercial") {
          if (sqft && sqft >= 4000) {
            off.price += Commercialabove4kVids.price;
          }
        } else if (off.code === "MvCA" && categorySelected === "Multifamily") {
          if (sqft && sqft >= 4000) {
            off.price += Multifamilyabove4kVids.price;
          }
        } else if (off.code === "vAp" && categorySelected === "Residential") {
          if (sqft && sqft >= 4000) {
            off.price += Residentialabove4kVids.price;
          }
        } else if (off.code === "CvAp" && categorySelected === "Commercial") {
          if (sqft && sqft >= 4000) {
            off.price += Commercialabove4kVids.price;
          }
        } else if (off.code === "MvAp" && categorySelected === "Multifamily") {
          if (sqft && sqft >= 4000) {
            off.price += Multifamilyabove4kVids.price;
          }
        } else if (off.code === "vWAp" && categorySelected === "Residential") {
          if (sqft && sqft >= 4000) {
            off.price += Residentialabove4kVids.price;
          }
        } else if (off.code === "CvWAp" && categorySelected === "Commercial") {
          if (sqft && sqft >= 4000) {
            off.price += Commercialabove4kVids.price;
          }
        } else if (off.code === "MvWAp" && categorySelected === "Multifamily") {
          if (sqft && sqft >= 4000) {
            off.price += Multifamilyabove4kVids.price;
          }
        } else if (off.code === "vCAp" && categorySelected === "Residential") {
          if (sqft && sqft >= 4000) {
            off.price += Residentialabove4kVids.price;
          }
        } else if (off.code === "CvCAp" && categorySelected === "Commercial") {
          if (sqft && sqft >= 4000) {
            off.price += Commercialabove4kVids.price;
          }
        } else if (off.code === "MvCAp" && categorySelected === "Multifamily") {
          if (sqft && sqft >= 4000) {
            off.price += Multifamilyabove4kVids.price;
          }
        } else if (off.code === "vW" && categorySelected === "Residential") {
          if (sqft && sqft >= 4000) {
            off.price += Residentialabove4kVids.price;
          }
        } else if (off.code === "CvW" && categorySelected === "Commercial") {
          if (sqft && sqft >= 4000) {
            off.price += Commercialabove4kVids.price;
          }
        } else if (off.code === "MvW" && categorySelected === "Multifamily") {
          if (sqft && sqft >= 4000) {
            off.price += Multifamilyabove4kVids.price;
          }
        } else if (off.code === "vC" && categorySelected === "Residential") {
          if (sqft && sqft >= 4000) {
            off.price += Residentialabove4kVids.price;
          }
        } else if (off.code === "CvC" && categorySelected === "Commercial") {
          if (sqft && sqft >= 4000) {
            off.price += Commercialabove4kVids.price;
          }
        } else if (off.code === "MvC" && categorySelected === "Multifamily") {
          if (sqft && sqft >= 4000) {
            off.price += Multifamilyabove4kVids.price;
          }
        } else if (off.code === "ae" && categorySelected === "Residential") {
          if (sqft && sqft >= 4000) {
            off.price += Residentialabove4kVids.price;
          }
        } else if (off.code === "Cae" && categorySelected === "Commercial") {
          if (sqft && sqft >= 4000) {
            off.price += Commercialabove4kVids.price;
          }
        } else if (off.code === "Mae" && categorySelected === "Multifamily") {
          if (sqft && sqft >= 4000) {
            off.price += Multifamilyabove4kVids.price;
          }
        } else if (
          off.code === "VR" &&
          categorySelected === "Residential" &&
          dollHousePrice
        ) {
          off.price = dollHousePrice;
        } else if (
          off.code === "CVR" &&
          categorySelected === "Commercial" &&
          dollHousePrice
        ) {
          off.price = dollHousePrice;
        } else if (
          off.code === "MVR" &&
          categorySelected === "Multifamily" &&
          dollHousePrice
        ) {
          off.price = dollHousePrice;
        } else if (off.code === "SMV" && categorySelected === "Residential") {
          off.price = socialMediaPrice ? socialMediaPrice : off.price;
        } else if (off.code === "CSMV" && categorySelected === "Commercial") {
          off.price = socialMediaPrice ? socialMediaPrice : off.price;
        } else if (off.code === "MSMV" && categorySelected === "Multi-family") {
          off.price = socialMediaPrice ? socialMediaPrice : off.price;
        } else if (off.code === "ASMV" && categorySelected === "Additional") {
          off.price = socialMediaPrice ? socialMediaPrice : off.price;
        }
      });
      setState({ ...state, updatedOfferings: newOfferings });
    }, [shoot_type, shoot]); // eslint-disable-line react-hooks/exhaustive-deps

    const findShootbyHsn = async (hsn) => {
      const res = await fetchShootbyHsn(hsn);
      if (!res?.error) {
        setFoundShoot(res?.data);
      } else {
        message.error(res?.error?.data);
        setFoundShoot(null);
      }
    };

    const handleConfirmShootId = () => {
      setState({
        ...state,
        shoot: foundShoot,
        shootId: foundShoot?._id,
        clientId: foundShoot?.client_id,
        brokerageId: foundShoot?.brokerage ? foundShoot?.brokerage : null,
        newOfferingsIds: [],
        shoot_type: null,
        subtotal: 0,
      });
    };

    const clearFoundShoot = () => {
      setFoundShoot(null);
    };

    const onSearch = (value) => {
      if (value) {
        findShootbyHsn(value);
      } else {
        setFoundShoot(null);
      }
    };

    const handleDeselect = (value) => {
      const offering = state.updatedOfferings.filter(
        (off) => off._id === value
      )[0];
      const arr = [...newOfferingsIds];
      arr.splice(arr.indexOf(value), 1);
      const subtotal = state?.subtotal - offering?.price;
      setState({
        ...state,
        newOfferingsIds: arr,
        subtotal: subtotal,
      });
    };

    const deletePackage = (id) => {
      const currOfferings = [...currentOfferings];
      currentOfferings.forEach((off, index) => {
        if (off._id === id) {
          currOfferings.splice(index, 1);
          const subtotal = state.subtotal - off.price;
          setState({
            ...state,
            currentOfferings: currOfferings,
            subtotal: subtotal,
          });
        }
      });
    };

    return (
      <React.Fragment>
        <Row>
          {mode === "add" && (
            <>
              <Col span={16} offset={4}>
                <Search
                  placeholder="Search by HSN"
                  onSearch={onSearch}
                  style={{
                    width: 300,
                  }}
                />
                {foundShoot && (
                  <>
                    <div className="mt-2">
                      <h4>We found:</h4>
                      <p>
                        HS# {foundShoot?.hsn} -{" "}
                        {foundShoot?.address?.street?.replace("null", "")}
                      </p>
                      <p>
                        {foundShoot?.client_name_first}{" "}
                        {foundShoot?.client_name_last}
                      </p>
                      <div className="mt-3">
                        <Button
                          type="primary"
                          style={{ marginRight: "10px" }}
                          onClick={() => handleConfirmShootId(foundShoot._id)}
                          disabled={!foundShoot}
                        >
                          Confirm
                        </Button>
                        <Button
                          onClick={() => clearFoundShoot()}
                          disabled={foundShoot}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </>
                )}

                {/* <Select
                  showSearch
                  style={{ width: "100%", padding: "5px" }}
                  placeholder="Select Shoot"
                  onChange={(val) => handleChange(val, "shootId")}
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {shoots?.map((sht) => (
                    <Option key={sht._id} value={sht._id}>
                      {`${sht.hsn} - ${sht.address.street} - ${sht.client_name_first} ${sht.client_name_last}`}
                    </Option>
                  ))}
                </Select> */}
              </Col>
              {/* {showClient && (
                <Col span={16} offset={4}>
                  <Select
                    showSearch
                    style={{ width: "100%", padding: "5px" }}
                    placeholder="Select Client"
                    onChange={(val) => handleChange(val, "clientId")}
                    filterOption={(input, option) => {
                      if (option?.children) {
                        return (
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }
                      return false;
                    }}
                  >
                    {clients?.map((clt) => (
                      <Option key={clt._id} value={clt._id}>
                        {clt.full}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )} */}
              {/* {showClient && ( */}
              <Col span={16} offset={4}>
                <Select
                  allowClear
                  style={{ width: "100%", padding: "5px" }}
                  placeholder="Select Shoot Type"
                  onChange={(val) => handleChange(val, "shoot_type")}
                >
                  <Option value="Residential">Residential</Option>
                  <Option value="Commercial">Commercial</Option>
                  <Option value="Multi-family">Multi-Family</Option>
                </Select>
              </Col>
              {/* )} */}
            </>
          )}
          {invoice?.shoot && invoice?.shoot.address && (
            <Col span={24} align="middle" style={{ padding: "10px" }}>
              <Typography.Text strong={true}>
                {invoice?.shoot.address.full}
              </Typography.Text>
            </Col>
          )}
          <Col span={24} style={{ padding: "10px" }}>
            {invoice?.canceled && (
              <Tag
                closable
                onClose={() =>
                  setState({
                    ...state,
                    canceled: false,
                  })
                }
              >
                Cancelled
              </Tag>
            )}
            {invoice?.reschedule && (
              <Tag
                closable
                onClose={() => setState({ ...state, reschedule: false })}
                color="pink"
              >
                Rescheduled
              </Tag>
            )}
          </Col>
          <Col span={24}>
            {currentOfferings &&
              currentOfferings?.map((offering, index) => (
                <Row key={index}>
                  <Col span={16} className="invoice-line-item">
                    {offering.description === "Subdivision Amenity Package" ? (
                      <span>
                        {offering.description + " "}
                        {(auth?.user?.access.includes("ADMIN") ||
                          auth?.user?.access.includes("COMMS")) && (
                          <Button
                            style={{ marginLeft: "50px" }}
                            type="primary"
                            onClick={showAmenityModal}
                          >
                            View Amenity
                          </Button>
                        )}
                        {amentityShow && (
                          <AmenityModal
                            updAmen={updAmen}
                            updSub={updSub}
                            updAmenity={updAmenity}
                            updSubdivision={updSubdivision}
                            invoice={invoice}
                            show={amentityShow}
                            closeModal={showAmenityModal}
                          />
                        )}
                      </span>
                    ) : (
                      offering.description
                    )}
                  </Col>
                  <Col
                    span={4}
                    className="invoice-line-item"
                    justify="center"
                    align="end"
                  >
                    {`$ ${offering.price.toFixed(2)}`}
                  </Col>
                  <Col
                    span={4}
                    justify="space-around"
                    align="middle"
                    style={{ padding: "7px" }}
                  >
                    <Button
                      type="ghost"
                      icon={<CloseOutlined />}
                      size="small"
                      onClick={() => deletePackage(offering._id)}
                    />
                  </Col>
                </Row>
              ))}
          </Col>
          <Col
            span={24}
            style={{ paddingTop: "20px", fontWeight: "bold" }}
            align="middle"
          >
            New Offerings
          </Col>
          <Col span={20} offset={2}>
            <Select
              mode="multiple"
              showSearch
              value={newOfferingsIds}
              style={{ width: "100%", padding: "5px" }}
              placeholder="Add new offering"
              onSelect={(val) => handleChange(val, "newOfferingsIds")}
              onDeselect={(val) => handleDeselect(val)}
              filterOption={(input, option) => {
                let offerings =
                  option.children.props.children[0].props.children;
                if (offerings) {
                  return (
                    offerings.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }
                return false;
              }}
            >
              {state.updatedOfferings &&
                state.updatedOfferings?.map((off) => (
                  <Option value={off._id} key={off._id}>
                    <Row key={off._id}>
                      <Col span={20}>{off.description}</Col>
                      <Col span={4} align="end">
                        ${off.price}
                      </Col>
                    </Row>
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            padding: "5px",
            marginTop: "10px",
            backgroundColor: "#eee",
          }}
        >
          <Col span={4}></Col>
          <Col span={4}></Col>
          <Col span={4}>Sub Total</Col>
          <Col span={4}>
            <span style={{ float: "right" }}>
              {state.subtotal
                ? `$ ${parseFloat(state.subtotal).toFixed(2)}`
                : `$ 0.0`}
            </span>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
);

export default InvoiceModal;
