import React from "react";
import { Row, Col, Avatar, Tooltip } from "antd";
import { connect } from "react-redux";

import {
  InstagramOutlined,
  TwitterOutlined,
  FacebookOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";

import "./banner.scss";
import { useEffect } from "react";
import { useState } from "react";

const Banner = ({ shoot: { selectedShoot } }) => {
  const [state, setState] = useState({
    isBranding: window.location.pathname.includes("/b/"),
    property_info: null,
    agent_info: null,
    brokerage_info: null,
    page_url: window.location,
    hideTab: window.location.search === "",
  });
  const { isBranding, property_info, agent_info } = state;
  useEffect(() => {
    if (selectedShoot && selectedShoot.media) {
      const { property_info } = selectedShoot.media;
      const { agent_info, brokerage_info } = selectedShoot.media.branding;
      setState({ ...state, property_info, agent_info, brokerage_info });
    }
  }, [selectedShoot]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {property_info && agent_info ? (
        <div>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={{ span: 18, offset: 3 }}
              lg={{ span: 18, offset: 3 }}
              xl={{ span: 18, offset: 3 }}
            >
              <Row justify="space-around" className="p-2">
                {isBranding && agent_info?.headshot && (
                  <Col flex="none">
                    <span style={{ maxWidth: 100, marginTop: 0 }}>
                      <Avatar
                        src={agent_info?.headshot}
                        alt="Add icon"
                        size={80}
                      />
                      {/* <Avatar
                    src={brokerage_info?.logo}
                    alt="Add"
                    size={35}
                    className="brokerage"
                  /> */}
                    </span>
                  </Col>
                )}
                <Col flex="auto">
                  <Row justify="space-between">
                    <Col>
                      <span className="titleStreetName">
                        {" "}
                        {property_info.street?.replace("null ", "")}
                      </span>{" "}
                      <span className="cityState">{`${
                        property_info?.city || ""
                      } ${property_info?.state || ""} ${
                        property_info?.zip || ""
                      }`}</span>
                      {isBranding && (
                        <div className="clientInfo">
                          <Row justify="space-between">
                            <Col className="clientName mr-4">
                              {agent_info.name}
                            </Col>{" "}
                            <Col className="mr-4">
                              <PhoneOutlined className="pr-1" />{" "}
                              {agent_info.phone}
                            </Col>
                            <Col>
                              <MailOutlined className="pr-1" />{" "}
                              {agent_info.email}
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col flex="none">
                  {isBranding && (
                    <Row
                      gutter={[8, 8]}
                      style={{ float: "right", marginTop: 0 }}
                    >
                      <Col>
                        <Tooltip
                          placement="bottomRight"
                          title="Share on Facebook"
                          color="#4e85f5"
                        >
                          <FacebookOutlined
                            onClick={() =>
                              window.open(
                                `https://www.facebook.com/sharer.php?u=${state.page_url}`,
                                "_blank"
                              )
                            }
                            className="fb"
                            style={{ color: "white", fontSize: "25px" }}
                          />
                        </Tooltip>
                      </Col>
                      <Col>
                        <Tooltip
                          placement="bottomRight"
                          title="Share on Instagram"
                          color="#df5885"
                        >
                          <InstagramOutlined
                            onClick={() =>
                              window.open(
                                `https://www.instagram.com/?url=${state.page_url}`,
                                "_blank"
                              )
                            }
                            className="insta"
                            style={{ color: "white", fontSize: "25px" }}
                          />
                        </Tooltip>
                      </Col>
                      <Col>
                        <Tooltip
                          placement="bottomRight"
                          title="Share on Twitter"
                          color="#4fb1ee"
                        >
                          <TwitterOutlined
                            onClick={() =>
                              window.open(
                                `https://twitter.com/intent/tweet?url=${state.page_url}`,
                                "_blank"
                              )
                            }
                            className="tweet"
                            style={{ color: "white", fontSize: "25px" }}
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  shoot: state.shoots,
});
export default connect(mapStateToProps, null)(Banner);
