import React, { useState } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Spin,
  Button,
  message,
  Switch,
  DatePicker,
  Select,
} from "antd";
import { Input, Form } from "formik-antd";
import { Formik } from "formik";

const { Option } = Select;

const CampaignForm = ({ 
  mode,
  initVal,
  close,
  submitHandler,
  offerings 
}) => {
  const [state, setState] = useState({ loader: false });
  const { loader } = state;
  const [isActive, setIsActive] = useState(initVal?.active || false);
  const [startDate, setStartDate] = useState(
    initVal?.startDate ? moment(initVal?.startDate) : null
  );
  const [endDate, setEndDate] = useState(
    initVal?.endDate ? moment(initVal?.endDate) : null
  );

  return (
    <Formik
    enableReinitialize
    initialValues={{
      ...initVal,
      offering: initVal?.offering?._id || null,
    }}
    onSubmit={async (values) => {
      setState({ ...state, loader: true });

    const res = await submitHandler({
      ...values,
      offering: values?.offering?._id || values.offering, 
      endDate: endDate ? moment(endDate).toISOString() : null,
      active: isActive,
    });
    if (res.status === 200 || res.status === 201) {
      mode === "Add"
        ? message.success("Campaign created successfully!")
        : message.success("Campaign updated successfully!");
    } else {
      message.error("Oops! Error occurred while creating campaign.");
    }

    setState({ ...state, loader: false });
    close(res.data);
  }}
>
  {({ handleSubmit, setFieldValue, values }) => (
    <div className="form-container">
      <Spin spinning={loader}>
        <Form layout="vertical">
          <Row gutter={[16, 16]}>
            {/* Campaign Name */}
            <Col span={24}>
              <Form.Item label="Campaign Name" name="name">
                <Input name="name" />
              </Form.Item>
            </Col>
            {/* Offering Selection */}
            <Col span={12}>
            <Form.Item label="Offering" name="offering">
              <Select
                value={values?.offering} 
                onChange={(value) => setFieldValue("offering", value)} 
                placeholder="Select an offering"
              >
                {offerings.offerings.map((offering) => (
                  <Option key={offering._id} value={offering._id}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <span>{offering.description}</span>
                      <span>${offering.price}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Offer Price" name="offerDetails.price">
                  <Input
                    name="offerDetails.price"
                    type="number"
                    addonBefore="$"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Offer Price Label" name="offerDetails.priceLabel">
                <Input name="offerDetails.priceLabel" />
                </Form.Item>
                </Col>
                <Col span={12}>
                <Form.Item label="Offer Additional Info" name="offerDetails.additionalInfo">
                <Input name="offerDetails.additionalInfo" />
                </Form.Item>
              </Col>
                <Col span={24}>
                <Form.Item label="Ad Title" name="ad.title">
                  <Input name="ad.title" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Ad Description" name="ad.description">
                  <Input.TextArea name="ad.description" rows={4} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Ad Image URL" name="ad.imageUrl">
                  <Input name="ad.imageUrl" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Display Type" name="ad.displayType">
                  <Select
                    value={values.ad?.displayType}
                    onChange={(value) => setFieldValue("ad.displayType", value)}
                    placeholder="Select display type"
                  >
                    <Option value="popup">Popup</Option>
                    <Option value="banner">Banner</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Variation" name="ad.variation">
                  <Input name="ad.variation" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Campaign Type" name="campaignType">
                  <Select
                    value={values?.campaignType}
                    onChange={(value) => setFieldValue("campaignType", value)}
                    placeholder="Select campaign type"
                  >
                    <Option value="Upsell">Upsell</Option>
                    <Option value="Order Bump">Order Bump</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item label="Start Date" name="startDate">
                <DatePicker
                  value={startDate}
                  onChange={(value) => {
                    setStartDate(value);
                    setFieldValue("startDate", value ? moment(value).toISOString() : null);
                  }}
                />
              </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item label="End Date" name="endDate">
                <DatePicker
                  value={endDate}
                  onChange={(value) => {
                    setEndDate(value);
                    setFieldValue("endDate", value ? moment(value).toISOString() : null);
                  }}
                />
                </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Active" name="active">
                    <Switch
                      checked={isActive}
                      onChange={(checked) => {
                        setIsActive(checked);
                        setFieldValue("active", checked);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    style={{ marginTop: "10px" }}
                  >
                    Save
                  </Button>
                  <Button type="link" onClick={close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      )}
    </Formik>
  );
};

export default CampaignForm;
