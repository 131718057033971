export const WORKED_BEFORE = "isWorkedBefore";
export const BOOKING_FORM = "bookingForm";
export const TIME_OF_DAY = "timeOfDay";
export const CUSTOM = "custom";
export const PACKAGES = "packages";
export const SHOOT_TYPE = "shootType";
export const INFO = "info";
export const CLOUD_BACKUP = "cloud_backup";
export const NEIGHBORHOOD = "neighborhood";
export const FILL_AS = "fillAs";
export const GUEST = "guest_modal";
export const PROPERTY = "property";
export const WHEN_READY = "whenReady";
export const APPT_TIME = "appointmentTime";
export const MTHD_ENTRY_TYP_1 = "methodOfEntry_type1";
export const MTHD_ENTRY_TYP_2 = "methodOfEntry_type2";
export const INT_EXT_PHOTOS = "interiorExteriorPhotos";
export const DRONE_PHOTOS = "dronePhotos";
export const DOLL_HOUSE = "dollHouse";
export const FLOOR_PLAN = "floorPlan";
export const DIGITAL_TWILIGHT = "digitalTwilight";
export const ORDER_BUMP = "orderBump";
export const ADDNL_OFF = "additionalOffers";
export const REFERRAL = "referral";
export const COVID = "covid";
export const PRIMARY_CONTACT = "primary_contact";
export const SECONDARY_CONTACT = "secondary_contact";
export const SHARE = "share";
export const NOTES = "clientNote";
export const SUMMARY = "summary";
export const INVOICE = "invoice";
export const PHOTOS = "photos";
export const VIDEOS = "videos";
export const EVENTS = "events";
export const ADDRESS = "address";
export const ADDRESS_MODAL = "address_modal";
export const CLIENT = "client";
export const TRIPCHARGE = "tripCharge";
// export const CLEAR = "clear";
export const CLEAR_FORM = "clear_form";
export const CLEAR_ALL = "clear_all";
export const NOT_AVL = "Not Available";
export const PROS = "Processing";
export const READY = "Ready";
export const PAYMENT_CARD = "paymentcard";
export const ZILLOW_SHOWCASE = "zillowShowcase";

export const INITIALSHOOTSTATE = {
  trip_charge: 0,
  new_client_bool: null,
  submission_datetime: "",
  reqs: [],
  reschedules: 0,
  reload_bf_link: "",
  referral_credit: false,
  contact_homeowner_bool: false,
  flyer: {
    sent_to_editor: false,
    form_sent: false,
    sent: false,
  },
  slideshow: {
    sent: false,
  },
  billing: {
    email_before: "",
    referred_by: "",
    full: "",
    last: "",
    kind: "",
    phone: "",
    autopay: null,
    email: "",
    tax_exempt: null,
    credit_amount: 0,
    first: "",
  },
  photos: {
    turnaround: "",
    dropbox_path: "",
    ie_taken: false,
    edited: false,
    download_link: "",
    ie_transferred: false,
    ie_package: "",
    sent_to_editor: false,
    sent: false,
    share_link: "",
    aerial_taken: false,
    processed: false,
    aerial_transferred: false,
    aerial_package: "",
    notes: "",
  },
  digital_twilight: false,
  invoice: {
    subtotal: 0.0,
    sent: false,
    discount: 0.0,
    paid: false,
    total: 0.0,
    balance: 0.0,
    tax_exempt: false,
    products: [],
  },
  subdivision: null,
  appliedCreditsTotal: 0,
  appliedCredits: [],
  share: [],
  neighborhood_amenity: false,
  intExtPhotos: true,
  amenities: [],
  selectedCard: "",
  video: {
    ground_footage_taken: false,
    sent_to_editor: false,
    ground_footage_files: 0,
    edited: false,
    sent: false,
    share_link: "",
    aerial_footage_files: 0,
    aerial_footage: false,
    aerial_footage_taken: false,
    ground_footage: true,
    taken: false,
    turnaround: "",
    aerial_footage_transferred: false,
    package: "",
    ground_footage_transferred: false,
  },
  booking_form: {
    is_deluxe: false,
    shoot_type: "",
    time_of_day: "",
    cloud_backup: "",
    neighborhood: "",
    package: "custom services",
    google_address: "",
    doll_price: "",
    v_turnaround: "",
    sqft: null,
    list_price: null,
    share: [],
    guest: "",
    ie_photos: "",
    refer_fn: "",
    refer_ln: "",
    drone_focus: "",
    any_drone: "No",
    drone_photos: "",
    drone_type: "",
    agent_e: "",
    agent_p: "",
    agent_fn: "",
    agent_ln: "",
    asst_p: "",
    asst_e: "",
    asst_fn: "",
    asst_ln: "",
    ho_ln: "",
    ho_fn: "",
    ho_e: "",
    ho_p: "",
    doll: "",
    floor_plan: "No",
    social_media: "No",
    social_media_links: {
      facebook: "",
      instagram: "",
    },
    address: "",
    additional_premier_offerings: [],
    hear_by: "",
    driving_directions: "",
    resi: "Occupied",
    when: "",
    agent_onsite: false,
    date: "",
    alternative_date: "",
    p_turnaround: "",
    fill_as: "",
    appt_type: "Go Anytime",
    video_type: "None",
    entry_id: "",
    entry_datetime: "",
    city: "",
    drive_time: "",
    entry_method: "",
    zip_code: null,
    street: "",
    state: "",
    apt: "",
    requested_date: null,
    photographerNote: {
      text: "",
    },
    clientNote: {
      text: "",
    },
    amenityNote: {
      text: "",
    },
    secondary_contact: {
      name: "",
      email: "",
      phone: "",
      role: "",
    },
    primary_contact: {
      client_name_first: null,
      client_name_last: null,
      client_email: null,
      client_phone: null,
      client_hsf: null,
      client_role: null,
      client_id: null,
      client_headshot_url: null,
    },
    zillowshowcase: "No",
  },
  address: {
    drive_time: "",
    street: "",
    apt: "",
    city: "",
    state: "",
    zip: "",
    street_lower_no_spaces: "",
    found: false,
    geometry: {
      type: "Point",
      coordinates: [],
    },
    full: "",
    city_state_zip: "",
  },
  dollhouse: {
    model_id: "",
    price: 0.0,
    sent: false,
    captured: false,
    processed: false,
  },
  checklist: {
    appointment_confirmed: false,
    invoice_paid: false,
    shoot_complete: false,
    photos_delivered: false,
  },
  events: {
    date: "",
    duration: 0,
    kind: "Pending",
    description: "",
    title: "",
    cal_id: "",
  },
  guest: false,

  client_name_first: "",
  client_name_last: "",
  client_email: "",
  client_phone: "",
  client_hsf: "",
  client_role: "",
  client_id: "",
  client_headshot_url: "",
  calendar_event_id: "",
  requested_date: null,
  campaign_code: "",
  status: "Pending",
  user: "",
  brokerage: null,
  checkins: [
    {
      checkin_type: "",
      checkin_time: "",
      checkin_location: {
        geometry: {
          type: "Point",
          coordinates: [],
        },
      },
    },
  ],
  zillowshowcase: {
    price: 0.0,
    sent: false,
    processed: false,
  },
  _created_at: "",
  _updated_at: "",
};
