import axios from "axios";
import {
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_FAIL,
  FETCH_CAMPAIGN_BY_ID_SUCCESS,
  FETCH_CAMPAIGN_BY_ID_FAIL,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  REMOVE_CAMPAIGN_SUCCESS,
  REMOVE_CAMPAIGN_FAIL,
  // FETCH_CAMPAIGNS_TABLEVIEW_SUCCESS,
  // FETCH_CAMPAIGNS_TABLEVIEW_FAIL,
} from "./types";

export const fetchCampaigns = (params) => async (dispatch) => {

  try {
    const res = await axios.get(`${process.env.REACT_APP_ROOT_URL}/campaigns`);
    dispatch({
      type: FETCH_CAMPAIGNS_SUCCESS,
      payload: {
        campaigns: res.data,  
        count: res.data.length,  
      },
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (err) {
    console.error("Error fetching campaigns:", err.response || err.message);  
    dispatch({
      type: FETCH_CAMPAIGNS_FAIL,
    });
    return {
      data: null,
      error: err.response || err.message,
    };
  }
};

// export const fetchCampaignsTableView = (params) => async (dispatch) => {
//   try {
//     const { search, skip, limit } = params;
//     const res = await axios.get(
//       `${process.env.REACT_APP_ROOT_URL}/campaigns/tableview?search=${search}&skip=${skip}&limit=${limit}`
//     );

//     dispatch({
//       type: FETCH_CAMPAIGNS_TABLEVIEW_SUCCESS,
//       payload: res.data,
//     });
//   } catch (err) {
//     dispatch({
//       type: FETCH_CAMPAIGNS_TABLEVIEW_FAIL,
//     });
//     return {
//       data: null,
//     };
//   }
// };

export const fetchCampaignById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ROOT_URL}/campaigns/${id}`
    );
    dispatch({
      type: FETCH_CAMPAIGN_BY_ID_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.error("Error fetching campaign by ID:", err.response || err.message);
    dispatch({
      type: FETCH_CAMPAIGN_BY_ID_FAIL,
    });
    return {
      data: null,
      error: err.response || err.message,
    };
  }
};

export const createCampaign = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_ROOT_URL}/campaigns`,
      formData
    );
    console.log(res.data);
    dispatch({
      type: CREATE_CAMPAIGN_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (err) {
    console.error(
      "Error occured while creating the campaign as " + err.message
    );
    dispatch({
      type: CREATE_CAMPAIGN_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const updateCampaign = (id, formData) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_URL}/campaigns/${id}`,
      formData
    );
    dispatch({
      type: UPDATE_CAMPAIGN_SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (err) {
    console.log("Error occured while updating the campaign as " + err.message);
    dispatch({
      type: UPDATE_CAMPAIGN_FAIL,
    });
    return {
      data: null,
      error: err.response,
    };
  }
};

export const removeCampaign = (id) => async (dispatch) => {

  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_ROOT_URL}/campaigns/${id}`
    );
    dispatch({
      type: REMOVE_CAMPAIGN_SUCCESS,
      payload: id,
    });
    return res;
  } catch (err) {
    console.error("Error removing campaign:", err.response || err.message);  // Log the error details
    dispatch({
      type: REMOVE_CAMPAIGN_FAIL,
    });
    return {
      data: null,
      error: err.response || err.message,
    };
  }
};
