import React, { useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Empty,
  Button,
  message,
  Input,
  Switch,
  Form,
  Tooltip,
  Alert,
  Typography,
} from "antd";
import Loading from "../../../shared/loading/Loading.js";
import { PROS, READY } from "../../../redux/action/shoot/shootConstants.js";
import ability from "../../../user/Component/Auth/ability";
import { CopyOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ZillowShowcase = ({
  selectedShoot,
  media,
  updateMedia,
  createActivity,
  denyMediaAccess,
}) => {
  const [loader, setLoader] = useState(false);
  const [showcaseUrl, setShowcaseUrl] = useState(
    media?.zillowShowcase?.url || ""
  );
  const { zillowShowcase } = media;

  const handleSave = async () => {
    if (!showcaseUrl) {
      message.error("Please enter a Zillow Showcase URL");
      return;
    }

    setLoader(true);
    const res = await updateMedia(media._id, {
      zillowShowcase: {
        ...media.zillowShowcase,
        url: showcaseUrl,
        status: PROS,
      },
    });
    if (!res.error) {
      message.success("Zillow Showcase URL updated successfully!");
      createActivity(selectedShoot, "Zillow Showcase URL updated.");
    } else {
      message.error("Error occurred while updating Zillow Showcase URL");
    }
    setLoader(false);
  };

  const changeHandler = async (value) => {
    const updatedStatus = value ? READY : PROS;
    const res = await updateMedia(media._id, {
      zillowShowcase: { ...media.zillowShowcase, status: updatedStatus },
    });
    if (!res.error) {
      message.success("Zillow Showcase status updated successfully!");
      if (updatedStatus === READY) {
        createActivity(selectedShoot, `Zillow Showcase marked as ready.`);

        // Prepare email data matching mediaReady function requirements
        const emailData = {
          shootId: selectedShoot._id,
          clientEmail: selectedShoot.client_email,
          hsn: selectedShoot.hsn,
          address: selectedShoot.address,
          mediaType: "zillowShowcase",
          clientFirst: selectedShoot?.client_name_first,
          clientLast: selectedShoot?.client_name_last,
          secondaryContact: selectedShoot?.booking_form?.secondary_contact,
          share: selectedShoot.share,
          zillowShowcaseUrl: `${process.env.REACT_APP_ROOT_URL}/admin/shoots/${selectedShoot._id}?tab=zillowShowcase`,
        };

        const res = await axios.post(
          `${process.env.REACT_APP_ROOT_URL}/notifications/photos-ready`,
          emailData
        );
        if (res.status === 200) {
          createActivity(
            selectedShoot,
            "Zillow Showcase ready email sent to client."
          );
        }
      } else {
        createActivity(
          selectedShoot,
          `Zillow Showcase status changed to not ready.`
        );
      }
    } else {
      message.error("Error occurred while updating Zillow Showcase status");
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(showcaseUrl);
      message.success("URL copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy URL");
    }
  };

  const renderShowcaseContent = () => {
    return (
      <>
        {denyMediaAccess && (
          <Alert
            message={
              <div>
                <a
                  href={`/admin/invoices/${selectedShoot.invoice[0]?._id}`}
                  style={{ color: "#1890ff" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Please pay your invoice
                </a>{" "}
                to access your media.
              </div>
            }
            style={{ marginBottom: 10 }}
            banner
          />
        )}

        {/* Admin Controls */}
        {ability.can("edit", "ZillowShowcase") && (
          <Row
            style={{
              padding: 20,
              background: "#f3f7ff",
              marginBottom: 20,
              borderRadius: 8,
            }}
          >
            <Col span={24}>
              <Form layout="vertical">
                <Form.Item
                  label={<Text strong>Add/Update Zillow Showcase URL</Text>}
                  help="Enter the URL for this property's Zillow Showcase"
                >
                  <Input.Group compact>
                    <Input
                      value={showcaseUrl}
                      onChange={(e) => setShowcaseUrl(e.target.value)}
                      style={{ width: "calc(100% - 100px)" }}
                      placeholder="https://www.zillow.com/homedetails/..."
                    />
                    <Button
                      type="primary"
                      onClick={handleSave}
                      disabled={!showcaseUrl}
                    >
                      Save URL
                    </Button>
                  </Input.Group>
                </Form.Item>
              </Form>
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Text type="secondary">
                Mark as ready when the Zillow Showcase is complete and available
                for viewing
              </Text>
              <Switch
                style={{ marginLeft: 10 }}
                onChange={changeHandler}
                checked={
                  media.zillowShowcase && media.zillowShowcase.status === READY
                }
              />
            </Col>
          </Row>
        )}

        {/* Showcase Display */}
        <Row>
          <Col span={24}>
            <h3>Zillow Showcase URL</h3>
            {zillowShowcase?.url ? (
              <div
                style={{
                  marginBottom: 20,
                  padding: 20,
                  background: "#fff",
                  borderRadius: 8,
                  border: "1px solid #f0f0f0",
                }}
              >
                <Text copyable style={{ fontSize: 16 }}>
                  {zillowShowcase.url}
                </Text>
                <div style={{ marginTop: 10 }}>
                  <Button
                    type="link"
                    icon={<CopyOutlined />}
                    onClick={copyToClipboard}
                  >
                    Copy URL
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => window.open(zillowShowcase.url, "_blank")}
                    style={{ marginLeft: 8 }}
                    disabled={denyMediaAccess}
                  >
                    View Showcase
                  </Button>
                </div>
              </div>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  ability.can("edit", "ZillowShowcase")
                    ? "Add a Zillow Showcase URL above"
                    : "No Zillow Showcase URL available yet"
                }
              />
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div style={{ padding: 20 }}>
      {loader && (
        <div className="loading-container">
          <Loading />
        </div>
      )}
      {!loader && renderShowcaseContent()}
    </div>
  );
};

export default ZillowShowcase;
