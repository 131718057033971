import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Checkbox,
  Modal,
  Card,
  Tabs,
  Button,
  Spin,
  Radio,
  Alert,
} from "antd";
import { SendOutlined, ArrowRightOutlined } from "@ant-design/icons";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import { connect } from "react-redux";
import {
  fetchPhotographers,
  fetchAllPhotoGraphersUnAvailabilityForADay,
} from "../../../redux/action/photographerAction";
import "tui-calendar/dist/tui-calendar.css";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import { fetchEntityActivities } from "../../../redux/action/activityAction";
import VirtualTour from "../Shoots/VirtualTour/VirtualTour";
import OrderDetails from "../Order/Common/OrderDetails";
// import Dollhouse from "../Shoots/Dollhouse";
import {
  convertISODateString,
  hasUnpaidInvoice,
  sortPhotographer,
} from "../../../shared/utils/utils";
import { updateMedia } from "../../../redux/action/mediaAction";
import { createActivity } from "../../../redux/action/activityAction";
import {
  fetchShootsByDay,
  fetchPendingShoots,
  updateShoot,
  fetchShootById,
} from "../../../redux/action/shootAction";
import { fetchAccessNotes } from "../../../redux/action/note";
import {
  CHANGE_CALENDAR_VIEW_DATE,
  SELECT_PHOTOGRAPHER,
} from "../../../redux/action/types";
import moment from "moment";
import Details from "../Shoots/Details/Details";
import { useHistory } from "react-router";
import ability from "../../../user/Component/Auth/ability";
import { CalendarOutlined, CarryOutOutlined } from "@ant-design/icons";

const EditorDashboard = (props) => {
  const calendarComponentRef = useRef();
  const [state, setState] = useState({
    resources: [],
    currDate: new Date(),
    photographers: [],
    calendarEvents: [],
    display: true,
    tempShoots: props.shoots, //props.shoots,
  });
  const [viewType, setViewType] = useState("Today");
  const [displayShoots, setDisplayShoots] = useState([]);
  const [loader, setLoader] = useState(true);
  const [shootSelected, setShootSelected] = useState({ shootSelected: null });
  const [selectedShootId, setSelectedShootId] = useState(null);
  const { TabPane } = Tabs;
  const [filter, setFilter] = useState();
  const history = useHistory();
  const { pathname } = history.location;
  useEffect(() => {
    const fetch = async () => {
      await props.changeDateView({
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      });
      await props.fetchShootsByDay({
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        access: props.auth.user.access,
      });
      setLoader(false);
    };
    fetch();
    setLoader(true);
  }, [props.changeDateView]); //eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    props.fetchPhotographers();

    const resources = [];
    props.photographers.forEach((pht) => {
      if (pht.active) {
        pht.title = pht.name.split(" ")[0];
        pht.id = pht.pg_id;
        pht.eventBackgroundColor = pht.bgColor;
        pht.eventBorderColor = pht.borderColor;
        pht.opacity = 0.2;
        pht.showInfoWindow = false;
        resources.push(pht);
      }
    });
    setState({
      ...state,
      photographers: resources,
      resources,
    });
  }, [props.currShoots]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!displayShoots.length && state.display === true) {
      setDisplayShoots(props.currShoots);
    }
  }, [props.currShoots, props.changeDateView]); // eslint-disable-line react-hooks/exhaustive-deps

    
  const currentShoot = {
    ...shootSelected?.shootSelected,
    _id: shootSelected?.shootSelected?.id,
    client_email: shootSelected?.shootSelected?.email,
    client_name_first: shootSelected?.shootSelected?.name.split(" ")[0],
    client_name_last: shootSelected?.shootSelected?.name.split(" ")[1],
  };

  const calendarRef = useRef();

  const CheckboxonChange = (checkedValues) => {
    let shoots = [];
    if (checkedValues.length === 0) {
      setState({
        ...state,
        display: false,
      });
    }
    for (let i = 0; i < checkedValues.length; i++) {
      if (checkedValues[i] === "Next Business Day by 12:00p") {
        props.currShoots
          .filter((shoot) => shoot.details.reqs.includes("1D12!"))
          .map((shoot) => shoots.push(shoot));
      } else if (checkedValues[i] === "Next Business Day by 9:00p") {
        props.currShoots
          .filter((shoot) => shoot.details.reqs.includes("1D9"))
          .map((shoot) => shoots.push(shoot));
      } else if (checkedValues[i] === "2 Business Days by 12:00p") {
        props.currShoots
          .filter((shoot) => shoot.details.reqs.includes("FREE"))
          .map((shoot) => shoots.push(shoot));
      } else {
        shoots.push(shoots);
      }
    }
    setDisplayShoots(shoots);
  };
  const plainOptions = [
    "Next Business Day by 12:00p",
    "Next Business Day by 9:00p",
    "2 Business Days by 12:00p",
  ];

  const PhotoCheckboxonChange = (checkedValues) => {
    let shoots = [];
    if (checkedValues.length === 0) {
      setState({
        ...state,
        display: false,
      });
    }
    for (let i = 0; i < checkedValues.length; i++) {
      if (checkedValues[i] === "Four Hour Turnaround") {
        props.currShoots
          .filter((shoot) => shoot.details.reqs.includes("4H!"))
          .map((shoot) => shoots.push(shoot));
      } else if (checkedValues[i] === "Same Day by 9:00PM") {
        props.currShoots
          .filter((shoot) => shoot.details.reqs.includes("SD"))
          .map((shoot) => shoots.push(shoot));
      } else if (checkedValues[i] === "Next Business Day by 12:00PM") {
        props.currShoots
          .filter((shoot) => shoot.details.reqs.includes("FREE"))
          .map((shoot) => shoots.push(shoot));
      } else {
        shoots.push(shoots);
      }
    }
    setDisplayShoots(shoots);
  };
  const photoPlainOptions = [
    "Four Hour Turnaround",
    "Same Day by 9:00PM",
    "Next Business Day by 12:00PM",
  ];

  const handleViewChange = (e) => {
    setViewType(e.target.value);
  };

  const changeDateView = async (from, to) => {
    setLoader(true);
    await props.changeDateView({ from, to });
    const res = await props.fetchShootsByDay({
      from,
      to,
      access: props.auth.user.access,
    });
    setFilter(res?.data?.shoots);
    setLoader(false);
  };

  const setVisible = (value) => {
    setState({
      ...state,
      visible: value,
    });
  };

  
  const showModal = ({ event }) => {
    const selectedShoot = props.currShoots.find((shoot) => shoot.id === event.id);
    if (selectedShoot) {
      setSelectedShootId(selectedShoot.id);
  
      props.fetchAccessNotes(selectedShoot.client_id, "EDITOR");
      props.fetchEntityActivities(selectedShoot.id);
  
      const isPending =
        event._def.resourceIds?.length === 1 &&
        event._def.resourceIds[0] === "Pending";
  
      const updatedSelectedShoot = isPending
        ? {
            ...selectedShoot,
            start: event.start?.toISOString(),
            end: event.end?.toISOString(),
            booking_form: {
              ...selectedShoot.details.booking_form,
            },
            currentPhotographer: {
              ...selectedShoot.details.events.photographer,
            },
            video: {
              ...selectedShoot.details.video,
            },
            address: {
              ...selectedShoot.details.address,
            },
            invoices: {
              ...selectedShoot.details,
            },
            events: {
              ...selectedShoot.details.events,
              start: event.start?.toISOString(),
              end: event.end?.toISOString(),
            },
            details: {
              ...selectedShoot.details,
              events: {
                ...selectedShoot.details.events,
                start: event.start?.toISOString(),
                end: event.end?.toISOString(),
              },
            },
          }
        : {
            ...selectedShoot,
            start: event.start?.toISOString(),
            end: event.end?.toISOString(),
            events: {
              ...selectedShoot.details.events,
              start: event.start?.toISOString(),
              end: event.end?.toISOString(),
            },
            address: {
              ...selectedShoot.details.address,
            },
            video: {
              ...selectedShoot.details.video,
            },
            currentPhotographer: {
              ...selectedShoot.details.events.photographer,
            },
            invoices: {
              ...selectedShoot.details,
            },
            booking_form: {
              ...selectedShoot.details.booking_form,
            },
            details: {
              ...selectedShoot.details,
              events: {
                ...selectedShoot.details.events,
                start: event.start?.toISOString(),
                end: event.end?.toISOString(),
              },
            },
          };
  
      setShootSelected({
        ...shootSelected,
        shootSelected: updatedSelectedShoot,
      });
    }
  
    setVisible(true);
  };
  const handleOk = () => {
    setState({
      ...state,
      selectedSchedule: null,
    });
    setVisible(false);
  };

  const handleCancel = () => {
    setState({
      ...state,
      selectedSchedule: null,
    });
    setVisible(false);
  };
  async function callback(key) {
    if (key === "3" && selectedShootId) {
      await props.fetchEntityActivities(selectedShootId);
    }
  }
  const operations = (
    <a href={`/admin/shoots/${selectedShootId}`}>

      <Button>
        Go to Shoot Details <ArrowRightOutlined />
      </Button>
    </a>
  );
  const tabChangeHandler = (tab) => {
    history.push({
      pathname,
      search: `?tab=${tab}`,
    });
  };

  return (
    <React.Fragment>
      <Row
        gutter={[5, 5]}
        style={{
          borderRadius: "3px",
          padding: "5px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Col
          md={4}
          style={{
            textAlign: "left",
          }}
        >
          <Row gutter={[5, 5]}>
            <Col
              md={24}
              style={{
                textAlign: "center",
                paddingTop: "4em",
              }}
            >
              <h3
                style={{
                  textAlign: "start",
                }}
              >
                {" "}
                Turnaround{" "}
              </h3>
            </Col>{" "}
            {props.auth.user.access.includes("EDITOR") &&
              props.auth.user.access.includes("VIDEO") && (
                <Col md={24}>
                  <Checkbox.Group
                    options={plainOptions}
                    defaultValue={plainOptions}
                    onChange={CheckboxonChange}
                  />
                  <br />
                  <br />
                </Col>
              )}
            {props.auth.user.access.includes("EDITOR") &&
              (props.auth.user.access.includes("PHOTO") ||
                props.auth.user.access.includes("DELUXE")) && (
                <Col md={24}>
                  <Checkbox.Group
                    options={photoPlainOptions}
                    defaultValue={photoPlainOptions}
                    onChange={PhotoCheckboxonChange}
                  />
                  <br />
                  <br />
                </Col>
              )}
          </Row>
        </Col>
        <Col span={20} className={"map-view"}>
          <Row gutter={[5, 5]}>
            <Col
              md={24}
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "3px",
                marginTop: "10px",
              }}
            >
              <Row>
                <Col md={24} style={{ textAlign: "center", paddingTop: 15 }}>
                  <Radio.Group size="medium" onChange={handleViewChange}>
                    <Radio.Button
                      value="Today"
                      className={viewType === "Today" ? "toggle-active" : ""}
                      onChange={() => changeDateView("Today")}
                    >
                      <CarryOutOutlined /> Today
                    </Radio.Button>
                    <Radio.Button
                      value="Calendar"
                      className={viewType === "Calendar" ? "toggle-active" : ""}
                      onChange={() => changeDateView("Calendar")}
                    >
                      <CalendarOutlined /> Calendar
                    </Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
              {viewType === "Today" && (
                <Spin spinning={loader}>
                  <FullCalendar
                    schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                    defaultView="resourceTimeGridDay"
                    defaultDate={convertISODateString(state.currDate)}
                    editable={false}
                    displayEventTime={true}
                    selectable={true}
                    timeZone="UTC"
                    backgroundColor="#fff"
                    allDaySlot={false}
                    nowIndicator={false}
                    height="auto"
                    snapDuration="00:05:00"
                    minTime="07:00:00"
                    maxTime="22:00:00"
                    scrollTime="07:00:00"
                    resources={state.resources}
                    plugins={[resourceTimeGridPlugin, interactionPlugin]}
                    ref={calendarComponentRef}
                    header={{
                      left: "prev",
                      center: "title",
                      right: "today next",
                    }}
                    resourceRender={(renderInfo) => {
                      if (renderInfo.resource.id === "Pending") {
                        renderInfo.el.style.background = "#a0000f";
                        renderInfo.el.style.color = "#ffffff";
                      }
                    }}
                    customButtons={{
                      prev: {
                        text: "<",
                        click: function () {
                          setDisplayShoots([]);
                          const newDate = new Date(
                            state.currDate.setDate(state.currDate.getDate() - 1)
                          );
                          changeDateView(
                            moment(newDate.toString()).format("YYYY-MM-DD"),
                            moment(newDate.toString()).format("YYYY-MM-DD")
                          );
                          const calendarApi =
                            calendarComponentRef.current.getApi();
                          calendarApi.gotoDate(newDate);
                        },
                      },
                      next: {
                        text: ">",
                        click: function () {
                          setDisplayShoots([]);
                          const newDate = new Date(
                            state.currDate.setDate(state.currDate.getDate() + 1)
                          );
                          changeDateView(
                            moment(newDate.toString()).format("YYYY-MM-DD"),
                            moment(newDate.toString()).format("YYYY-MM-DD")
                          );
                          const calendarApi =
                            calendarComponentRef.current.getApi();
                          calendarApi.gotoDate(newDate);
                        },
                      },
                      today: {
                        text: "today",
                        click: function () {
                          const newDate = new Date();
                          setState({ ...state, currDate: newDate });
                          setDisplayShoots([]);
                          changeDateView(
                            moment(newDate.toString()).format("YYYY-MM-DD"),
                            moment(newDate.toString()).format("YYYY-MM-DD")
                          );
                          const calendarApi =
                            calendarComponentRef.current.getApi();
                          calendarApi.gotoDate(newDate);
                        },
                      },
                    }}
                    eventClick={showModal}
                    events={
                      props.currShoots.length === displayShoots?.length
                        ? props.currShoots
                        : displayShoots
                    }
                  />
                </Spin>
              )}
              {viewType === "Calendar" && (
                <Row
                  gutter={[5, 5]}
                  style={{
                    borderRadius: "3px",
                    margin: "10px",
                    padding: "5px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <Col span={24} className={"map-view"}>
                    <Row gutter={[5, 5]}>
                      <Col
                        md={24}
                        style={{
                          border: "1px solid #d9d9d9",
                          borderRadius: "3px",
                          marginTop: "10px",
                        }}
                      >
                        <Spin spinning={loader}>
                          <FullCalendar
                            timeZone="UTC"
                            height={600}
                            defaultView="timeGridDay"
                            allDaySlot={false}
                            editable={false}
                            contentHeight="auto"
                            nowIndicator={false}
                            minTime="07:00:00"
                            maxTime="22:00:00"
                            scrollTime="07:00:00"
                            snapDuration="00:05:00"
                            header={{
                              left: "prev,next today",
                              center: "title",
                              right:
                                "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                            }}
                            customButtons={{
                              prev: {
                                text: "<",
                                click: function () {
                                  setDisplayShoots([]);
                                  const calendarApi =
                                    calendarRef.current.getApi();
                                  // calendarApi.gotoDate(newDate);
                                  calendarApi.prev();
                                  changeDateView(
                                    moment(
                                      calendarApi.view.activeStart.toString()
                                    ).format("YYYY-MM-DD"),
                                    moment(
                                      calendarApi.view.activeEnd.toString()
                                    ).format("YYYY-MM-DD")
                                  );
                                },
                              },
                              next: {
                                text: ">",
                                click: () => {
                                  setDisplayShoots([]);
                                  const calendarApi =
                                    calendarRef.current.getApi();
                                  // calendarApi.gotoDate(newDate);
                                  calendarApi.next();
                                  changeDateView(
                                    moment(
                                      calendarApi.view.activeStart.toString()
                                    ).format("YYYY-MM-DD"),
                                    moment(
                                      calendarApi.view.activeEnd.toString()
                                    ).format("YYYY-MM-DD")
                                  );
                                },
                              },
                              today: {
                                text: "today",
                                click: () => {
                                  setDisplayShoots([]);
                                  const newDate = new Date();
                                  changeDateView(
                                    moment(newDate.toString()).format(
                                      "YYYY-MM-DD"
                                    ),
                                    moment(newDate.toString()).format(
                                      "YYYY-MM-DD"
                                    )
                                  );
                                  const calendarApi =
                                    calendarRef.current.getApi();
                                  calendarApi.gotoDate(newDate);
                                },
                              },
                              timeGridWeek: {
                                text: "Week",
                                click: () => {
                                  setDisplayShoots([]);
                                  const calendarApi =
                                    calendarRef.current.getApi();
                                  calendarApi.changeView("timeGridWeek", {
                                    start: moment(state.currDate, "YYYY-MM-DD")
                                      .subtract(7, "days")
                                      .format("YYYY-MM-DD"),
                                    end: moment(state.currDate).format(
                                      "YYYY-MM-DD"
                                    ),
                                  });
                                  changeDateView(
                                    moment(
                                      calendarApi.view.activeStart.toString()
                                    ).format("YYYY-MM-DD"),
                                    moment(
                                      calendarApi.view.activeEnd.toString()
                                    )
                                      .subtract(1, "days")
                                      .format("YYYY-MM-DD")
                                  );
                                },
                              },
                              timeGridDay: {
                                text: "Day",
                                click: () => {
                                  setDisplayShoots([]);
                                  const calendarApi =
                                    calendarRef.current.getApi();
                                  calendarApi.changeView(
                                    "timeGridDay",
                                    moment(state.currDate).format("YYYY-MM-DD")
                                  );
                                  changeDateView(
                                    moment(
                                      calendarApi.view.activeStart.toString()
                                    ).format("YYYY-MM-DD"),
                                    moment(
                                      calendarApi.view.activeEnd.toString()
                                    )
                                      .subtract(1, "days")
                                      .format("YYYY-MM-DD")
                                  );
                                },
                              },
                              dayGridMonth: {
                                text: "Month",
                                click: () => {
                                  setDisplayShoots([]);
                                  const calendarApi =
                                    calendarRef.current.getApi();
                                  calendarApi.changeView("dayGridMonth", {
                                    start: moment(
                                      state.currDate,
                                      "YYYY-MM-DD"
                                    ).subtract(30, "days"),
                                    end: moment(state.currDate, "YYYY-MM-DD"),
                                  });
                                  changeDateView(
                                    moment(
                                      calendarApi.view.activeStart.toString()
                                    ).format("YYYY-MM-DD"),
                                    moment(
                                      calendarApi.view.activeEnd.toString()
                                    )
                                      .subtract(1, "days")
                                      .format("YYYY-MM-DD")
                                  );
                                },
                              },
                            }}
                            plugins={[
                              dayGridPlugin,
                              timeGridPlugin,
                              interactionPlugin,
                            ]}
                            ref={calendarRef}
                            events={
                              props.currShoots.length === displayShoots?.length
                                ? props.currShoots
                                : displayShoots
                            }
                            eventClick={showModal}
                          />
                        </Spin>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title="Order Details"
        visible={state.visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"80%"}
        style={{
          top: 20,
        }}
        footer={null}
        destroyOnClose={true}
      >
        {props.note?.accessNotes?.count > 0 &&
          props.auth?.user?.access.includes("EDITOR") && (
            <Alert
              message={`You have ${
                props.note?.accessNotes?.count
              } important note${
                props.note?.accessNotes?.count === 1 ? "" : "s"
              } to review`}
              type="warning"
              showIcon
              closable
            />
          )}
        <Card
          type="inner"
          size="small"
          style={{
            marginTop: 5,
          }}
        >
          {props.auth.user.access.includes("ADMIN") ||
          props.auth.user.access.includes("COMMS") ? (
            <OrderDetails
              selectedSchedule={state.selectedSchedule}
              photographers={props.photographers}
            />
          ) : (
            <>
              <Tabs
                size="large"
                type="card"
                style={{ margin: "0 10px" }}
                defaultActiveKey={"details"}
                tabBarExtraContent={operations}
                onChange={async (key) => {
                  tabChangeHandler(key);
                  await callback(key);
                }}
              >
                <TabPane
                  tab="Details"
                  key="details"
                  style={{ padding: "20px" }}
                >
                  {shootSelected.shootSelected &&
                    props.auth.user.access.includes("EDITOR") && (
                      <Details
                        update={props.updateShoot}
                        createActivity={props.createActivity}
                        selectedShoot={{
                          ...shootSelected.shootSelected,
                          _id: shootSelected.shootSelected.id,
                          status: shootSelected.shootSelected.shootStatus,
                        }}
                        activities={props.activity}
                        invoice={props.invoice}
                        auth={props.auth}
                        photographer={props.photographer}
                        setSelectedPhotographer={props.setSelectedPhotographer}
                        offerings={props.offerings}
                        fetchShootById={props.fetchShootById}
                        allPhotographerUnavailSlotsForDay={
                          props.allPhotographerUnavailSlotsForDay
                        }
                        fetchAllPhotoGraphersUnAvailabilityForADay={
                          props.fetchAllPhotoGraphersUnAvailabilityForADay
                        }
                      />
                    )}
                </TabPane>
                {props.auth.user.access.includes("EDITOR") &&
                  (props.auth.user.access.includes("PHOTO") ||
                    props.auth.user.access.includes("DELUXE")) &&
                  ability.can("manage", "Photos") && (
                    <TabPane tab={<span>Photos</span>} key="tour">
                      {shootSelected.shootSelected !== null && (
                        <VirtualTour
                          updateMedia={props.updateMedia}
                          updateShoot={props.updateShoot}
                          media={props.media}
                          auth={props.auth}
                          brokerages={props.brokerages}
                          selectedShoot={currentShoot}
                          createActivity={(shoot, text) =>
                            props.createActivity(shoot, text)
                          }
                          denyMediaAccess={
                            hasUnpaidInvoice(
                              shootSelected.selectedShoot?.invoice
                            ) &&
                            (!props.auth?.isAuthenticated ||
                              props.auth?.user?.access.includes("CLIENT"))
                          }
                        />
                      )}
                    </TabPane>
                  )}
                {props.auth.user.access.includes("EDITOR") &&
                  props.auth.user.access.includes("VIDEO") &&
                  ability.can("edit", "Video") &&
                  shootSelected.shootSelected !== null &&
                  shootSelected.shootSelected?.video?.package &&
                  shootSelected.shootSelected?.video?.package !==
                    "Social Media Video" && (
                    <TabPane tab={<span>Video</span>} key="tour-video">
                      <VirtualTour
                        updateMedia={props.updateMedia}
                        updateShoot={props.updateShoot}
                        media={props.media}
                        auth={props.auth}
                        brokerages={props.brokerages}
                        selectedShoot={currentShoot}
                        createActivity={(shoot, text) =>
                          props.createActivity(shoot, text)
                        }
                        denyMediaAccess={
                          hasUnpaidInvoice(
                            shootSelected.shootSelected?.invoice
                          ) &&
                          (!props.auth?.isAuthenticated ||
                            props.auth?.user?.access.includes("CLIENT"))
                        }
                      />
                    </TabPane>
                  )}
                {props.auth.user.access.includes("EDITOR") &&
                  props.auth.user.access.includes("VIDEO") &&
                  ability.can("edit", "Slideshow") &&
                  shootSelected.shootSelected !== null &&
                  shootSelected.shootSelected.details.reqs.includes("YT") && (
                    <TabPane tab={<span>Slideshow</span>} key="Youtube">
                      <VirtualTour
                        updateMedia={props.updateMedia}
                        updateShoot={props.updateShoot}
                        media={props.media}
                        auth={props.auth}
                        brokerages={props.brokerages}
                        selectedShoot={currentShoot}
                        createActivity={(shoot, text) =>
                          props.createActivity(shoot, text)
                        }
                        denyMediaAccess={
                          hasUnpaidInvoice(
                            shootSelected.shootSelected?.invoice
                          ) &&
                          (!props.auth?.isAuthenticated ||
                            props.auth?.user?.access.includes("CLIENT"))
                        }
                      />
                    </TabPane>
                  )}
                {props.auth.user.access.includes("EDITOR") &&
                  props.auth.user.access.includes("VIDEO") &&
                  ability.can("edit", "Social") &&
                  shootSelected.shootSelected !== null &&
                  (shootSelected.shootSelected.details.reqs.includes("SMV") ||
                    shootSelected.shootSelected.details.reqs.includes(
                      "SMV30"
                    ) ||
                    shootSelected.shootSelected.details.reqs.includes("CSMV") ||
                    shootSelected.shootSelected.details.reqs.includes("MSMV") ||
                    shootSelected.shootSelected.details.reqs.includes(
                      "ASMV"
                    )) && (
                    <TabPane tab={<span>Social Media</span>} key="socialMedia">
                      <VirtualTour
                        updateMedia={props.updateMedia}
                        updateShoot={props.updateShoot}
                        media={props.media}
                        auth={props.auth}
                        brokerages={props.brokerages}
                        selectedShoot={currentShoot}
                        createActivity={(shoot, text) =>
                          props.createActivity(shoot, text)
                        }
                        denyMediaAccess={
                          hasUnpaidInvoice(
                            shootSelected.shootSelected?.invoice
                          ) &&
                          (!props.auth?.isAuthenticated ||
                            props.auth?.user?.access.includes("SOCIAL"))
                        }
                      />
                    </TabPane>
                  )}
              </Tabs>
            </>
          )}
        </Card>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  let pg = [];
  let togglePg = [];
  pg.push({
    id: "0",
    pg_id: null,
    name: "New Order",
    bgColor: "#C0CA33",
    borderColor: "#C0CA33",
    dragBgColor: "#C0CA33",
  });
  togglePg.push({ id: "0", checked: true });
  sortPhotographer(state.photographer.photographers).forEach(
    (photographer, idx) => {
      pg.push({
        id: (idx + 1).toString(),
        pg_id: photographer._id,
        name: photographer.name,
        geometry: photographer.geometry,
        home_address: photographer.home_address,
        pay_multiplier: photographer.pay_multiplier,
        photog_id: photographer.photog_id,
        sp_cost_per_hour: photographer.sp_cost_per_hour,
        base_pay: photographer.base_pay,
        active: photographer.active,
        bgColor: photographer.color,
        borderColor: photographer.color,
        dragBgColor: photographer.color,
      });
      togglePg.push({ id: photographer._id, checked: true });
    }
  );
  return {
    photographers: pg,
    currShoots: state.shoots.dashboard.displayShoots,
    auth: state.auth,
    media: state.media.media ? state.media.media : {},
    activity: state.activity,
    invoice: state.invoice,
    photographer: state.photographer,
    currentPhotographer: state.photographer,
    brokerage: state.brokerages,
    offering: state.offering.offerings,
    allPhotographerUnavailSlotsForDay:
      state.photographer?.allPhotographerUnavailSlotsForDay,
    note: state.note,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchShootById: (id) => dispatch(fetchShootById(id)),
    updateShoot: (id, data) => dispatch(updateShoot(id, data)),
    updateMedia: (id, data) => dispatch(updateMedia(id, data)),
    createActivity: (shoot, text) => dispatch(createActivity(shoot, text)),
    fetchPhotographers: () => dispatch(fetchPhotographers()),
    fetchAllPhotoGraphersUnAvailabilityForADay: (date) =>
      dispatch(fetchAllPhotoGraphersUnAvailabilityForADay(date)),
    fetchPendingShoots: () => dispatch(fetchPendingShoots()),
    fetchShootsByDay: (params) => dispatch(fetchShootsByDay(params)),
    changeDateView: (date) =>
      dispatch({ type: CHANGE_CALENDAR_VIEW_DATE, payload: date }),
    fetchEntityActivities: (id) => dispatch(fetchEntityActivities(id)),
    setSelectedPhotographer: (phtg) => {
      return { type: SELECT_PHOTOGRAPHER, payload: phtg };
    },
    fetchAccessNotes: (entityId, access) =>
      dispatch(fetchAccessNotes(entityId, access)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorDashboard);
