import React, { useState, useEffect } from "react";
import moment from "moment";
import { renderLabel, eventDateFormat } from "../../../shared/utils/utils";
import { Typography, Checkbox } from "antd";
import { sendAppointmentConfirmation } from "../../../redux/action/notifications";
import { connect } from "react-redux";
import { fetchClientByUserId } from "../../../redux/action/clientAction";
import { createActivity } from "../../../redux/action/activityAction";
import { Select, message, Input, Form, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

let para_1;
// let para_2;
let para_3;
let para_4;
// let para_5;
// let para_6;
// let para_7;
let para_8;
let para_9;
let para_10;
let para_11;
let para_12;
let para_13;
let para_14;
let para_15;
let para_16;
var datas;
var secondaryContact;
var mailSender;
var mailList;
let sendAppointmentConfirmations;
let createNewActivity;
let currentUserId;
var mailtemplate;
export const onSendEmail = ({ sendAppointmentConfirmation }) => {
  const flexTrueData = {
    para_8: para_8.toString(),
    // para_2: para_2.toString(),
    para_3: para_3.toString(),
    // para_7: para_7.toString(),
    para_9: para_9?.toString(),
    para_10: para_10?.toString(),
    para_11: para_11?.toString(),
    para_12: para_12?.toString(),
    para_13: para_13?.toString(),
    para_14: para_14?.toString(),
  };
  const flexFalseData = {
    para_1: para_1.toString(),
    // para_2: para_2.toString(),
    para_3: para_3.toString(),
    para_4: para_4.toString(),
    // para_5: para_5.toString(),
    // para_6: para_6.toString(),
    // para_7: para_7.toString(),
    para_9: para_9?.toString(),
    para_10: para_10?.toString(),
    para_11: para_11?.toString(),
    para_12: para_12?.toString(),
    para_13: para_13?.toString(),
    para_14: para_14?.toString(),
  };
  const reschedule = {
    para_9: para_9?.toString(),
    para_10: para_10?.toString(),
    para_11: para_11?.toString(),
    para_12: para_12?.toString(),
    para_13: para_13?.toString(),
    para_14: para_14?.toString(),
    para_15: para_15.toString(),
    para_16: para_16.toString(),
  };
  const mailAddress = mailList?.concat(datas.client_email);
  if (secondaryContact) {
    mailAddress.push(secondaryContact);
  }
  const emailData = {
    to: mailAddress,
    subject: `${
      mailtemplate !== "reschedule" ? "Confirm Shoot" : "Appointment Time"
    }: ${datas.address.street?.replace("null", "")}`,
    name: datas.client_name_first + " " + datas.client_name_last,
    date: moment(datas.events.start).format("LL"),
    time: moment(datas.events.start).format("LT"),
    shootId: datas._id,
    shootType: datas.flexible,
    mailtemplate,
    email_content:
      mailtemplate === "flexible"
        ? flexTrueData
        : mailtemplate === "non-flexible"
        ? flexFalseData
        : reschedule,
  };
  sendAppointmentConfirmations(emailData);
  message.success(`email sent to ${datas.client_email}`);

  // Create activity
  const activityText = `${
    mailtemplate === "flexible"
      ? "Flexible Appointment"
      : mailtemplate === "non-flexible"
      ? "Confirmation"
      : "Tentative Appointment"
  } email sent to ${datas.client_email}`;

  createNewActivity({ ...datas, user: currentUserId }, activityText);
};

const AppointmentConfirmation = ({
  data,
  sendAppointmentConfirmation,
  auth,
  fetchClientByUserId,
  createActivity,
}) => {
  const [authenticated, setAuthenticated] = useState();
  useEffect(() => {
    const fetchForClients = async () => {
      const client = await fetchClientByUserId(auth.user._id);
      setAuthenticated(client.data);
    };
    fetchForClients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  datas = data;

  const { Option } = Select;
  mailSender = authenticated;
  sendAppointmentConfirmations = sendAppointmentConfirmation;
  createNewActivity = createActivity;
  currentUserId = auth.user._id;

  const [template, setTemplate] = useState({ value: "flexible" });
  const [receipientList, setReceipientList] = useState([]);
  const [secondaryCt, setSecondaryCt] = useState(
    data.booking_form.secondary_contact?.email
  );
  secondaryContact = secondaryCt;
  mailList = receipientList;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
  const teamMailIdChange = (e, index, type) => {
    const arr = receipientList;
    type === "delete" ? arr.splice(index, 1) : (arr[index] = e.target.value);
    setReceipientList(arr);
  };
  const [para1, setPara1] = useState(
    `Thank you for taking the time to fill out our booking form. We would be 
able to schedule a photographer to visit this property on ${moment
      .utc(data.events.start)
      .format("dddd")}, the ${moment
      .utc(data.events.start)
      .format("Do")} at ${moment
      .utc(data.events.start)
      .format("h:mma")
      .replace("m", "")}.
Would this work for you?`
    //   const [para1, setPara1] = useState(
    //     `Thank you for taking the time to fill out our booking form. We would be
    // able to schedule a photographer to visit this property on
    // ${eventDateFormat(data.events.start, data.events.end)}.
    // Please confirm this would be possible with your seller and let us know.
    // In order to confirm this availability, we will need a method of no-contact
    // entry such as a Supra iBox with CBS code, combo lockbox or a key hidden in
    // a secure location.`
  );
  //   const [para2, setPara2] = useState(
  //     `In an effort to increase our team members social distancing while
  // continuing to serve our valued clients, we will only accept booking
  // appointments that give our photographers no-contact access to the
  // properties they shoot - regardless of whether the properties are vacant or
  // occupied. If the property we are shooting is occupied, please make sure it
  // is vacant for the duration of the appointment.`
  //   );
  const [para3, setPara3] = useState(`
  Please review the appointment details for your property in the above email. If changes need to be made, you may request them by responding to this email, using the buttons at the bottom of this email, or by going through your online dashboard by selecting the “View or Edit Order details". If you have any questions please let us know.`);
  //   const [para3, setPara3] = useState(`
  // This will allow our photographers to work without having to meet with
  // agents and homeowners thereby helping to keep the community safe.`);
  const [para4, setPara4] = useState(`
Please make the appropriate plans to provide lockbox access to your
properties to make it possible for us to provide you with service. This
requirement will be in effect until further notice`);
  //   const [para5, setPara5] = useState(`
  // While Home Snappers is taking steps to limit the spread of the COVID-19
  // virus as we provide our services, Home Snappers is not legally liable for
  // any inadvertent exposure to the COVID-19 virus our team members may
  // facilitate. Booking agents and homeowners are responsible for taking
  // advance pre and post appointment precautions to protect themselves.`);
  //   const [para6, setPara6] = useState(`
  // We apologize for the inconvenience this requirement may cause you and look
  // forward to resuming business as usual once the COVID-19 threat is
  // neutralized.`);
  // const [para7, setPara7] = useState(`Have a great day!`);
  const [para8, setPara8] =
    useState(`Thank you for taking the time to fill out our booking form. We would be 
able to schedule a photographer to visit this property on
${moment.utc(data.events.start).format("dddd")}, the ${moment
      .utc(data.events.start)
      .format(
        "Do"
      )}. Should this appointment need to be rescheduled or cancelled, please do so before 10:00a the day prior to your appointment so as to avoid a reschedule fee. Please let us know if you have any questions!`);
  //   const [para8, setPara8] =
  //     useState(`Thank you for taking the time to fill out our booking form. We would be
  // able to schedule a photographer to visit this property on
  // ${eventDateFormat(
  //   data.events.start,
  //   data.events.end
  // )}. Let us know if you have any questions!`);
  const [para15, setPara15] = useState(
    `It looks like we are still needing to confirm that the appointment time works for you. We currently have your appointment tentatively set on our schedule for ${moment
      .utc(data.events.start)
      .format("dddd")}, the ${moment
      .utc(data.events.start)
      .format("Do")} at ${moment
      .utc(data.events.start)
      .format("h:mma")
      .replace(
        "m",
        ""
      )}. Once we receive confirmation that the appointment time works for you, we will be able to confirm this appointment.`
  );
  const [para16, setPara16] = useState(
    `If we are unable to confirm this appointment by ${moment
      .utc(data.events.start)
      .subtract(3, "hours")
      .format("h:mma")
      .replace(
        "m",
        ""
      )} tomorrow, it will automatically be postponed from our schedule.`
  );
  const para9 = authenticated?.headshot_url;
  const para10 = authenticated?.full;
  const para11 = authenticated?.designation;
  const para12 = "book@homesnappers.com";
  const para13 = authenticated?.phone;
  const para14 = "HOMESNAPPERS";
  const { Paragraph } = Typography;
  para_1 = para1;
  // para_2 = para2;
  para_3 = para3;
  para_4 = para4;
  // para_5 = para5;
  // para_6 = para6;
  // para_7 = para7;
  para_8 = para8;
  para_9 = para9;
  para_10 = para10;
  para_11 = para11;
  para_12 = para12;
  para_13 = para13;
  para_14 = para14;
  para_15 = para15;
  para_16 = para16;
  function onChange(value) {
    if (value === "flexible") {
      setTemplate({ value: "flexible" });
    } else if (value === "non-flexible") {
      setTemplate({ value: "non-flexible" });
    } else {
      setTemplate({ value: "reschedule" });
    }
  }
  mailtemplate = template.value;

  const renderCheckBox = (secondaryContact) => {
    return (
      <div>
        <Checkbox
          onChange={({ target: { checked } }) => {
            if (checked) {
              setSecondaryCt(secondaryContact);
            } else {
              setSecondaryCt("");
            }
          }}
          defaultChecked={true}
        >
          Secondary Contact
        </Checkbox>
      </div>
    );
  };

  return (
    <>
      <span className="mr-1">
        <strong>Select a Template:</strong>{" "}
      </span>
      <Select
        style={{ width: 200, paddingBottom: "10px" }}
        placeholder="Select a template"
        optionFilterProp="children"
        onChange={onChange}
        defaultValue={"flexible"}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="flexible">Flexible</Option>
        <Option value="non-flexible">Appointment Time</Option>
        <Option value="reschedule">Confirmation Email</Option>
      </Select>
      {template.value === "non-flexible" ? (
        <>
          <div>
            {renderLabel("To", data.client_email)}
            <Form name="dynamic_form_item" {...formItemLayoutWithOutLabel}>
              <Form.List name="names">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        onChange={(e) => teamMailIdChange(e, index, "add")}
                        {...(index === 0
                          ? formItemLayout
                          : formItemLayoutWithOutLabel)}
                        label={index === 0 ? "CC" : ""}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          noStyle
                        >
                          <Input
                            placeholder="Please enter email"
                            style={{ width: "60%" }}
                            onChange={(e) => teamMailIdChange(e, index, "add")}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={(e) => {
                              remove(field.name);
                              teamMailIdChange(e, index, "delete");
                            }}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: "60%" }}
                        icon={<PlusOutlined />}
                      >
                        Add field
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
            {data?.booking_form?.secondary_contact?.email &&
              renderCheckBox(data?.booking_form?.secondary_contact?.email)}
            {renderLabel("From", "book@homesnappers.com")}
            {renderLabel(
              "Subject",
              `Book a Shoot - ${data.address.street?.replace("null", "")}`
            )}
            <br />
            <p>Hello {data.name}</p>
            <Paragraph
              editable={{
                tooltip: "click to edit text",
                onChange: setPara1,
              }}
            >
              {para1}
            </Paragraph>
            {/* <Paragraph
              editable={{
                tooltip: "click to edit text",
                onChange: setPara2,
              }}
            >
              {para2}
            </Paragraph> */}
            {/* <Paragraph
              editable={{
                tooltip: "click to edit text",
                onChange: setPara4,
              }}
            >
              {para4}
            </Paragraph> */}
            <Paragraph
              editable={{
                tooltip: "click to edit text",
                onChange: setPara3,
              }}
            >
              {para3}
            </Paragraph>

            {/* <Paragraph
              editable={{
                tooltip: "click to edit text",
                onChange: setPara5,
              }}
            >
              {para5}
            </Paragraph> */}
            {/* <Paragraph
              editable={{
                tooltip: "click to edit text",
                onChange: setPara6,
              }}
            >
              {para6}
            </Paragraph> */}
            <Paragraph>Have a great day!</Paragraph>
            <>
              <br />
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                  <tr>
                    <td align="top">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tbody>
                          <tr>
                            <td width="140" align="top">
                              <img
                                src={authenticated?.headshot_url}
                                width="140"
                                height="140"
                                alt=""
                              />
                            </td>
                            <td width="30">
                              <table
                                width="1"
                                border="0"
                                align="center"
                                cellpadding="0"
                                cellspacing="0"
                              >
                                <tbody>
                                  <tr>
                                    <td width="5" bgcolor="#008bd0">
                                      <img
                                        src="https://homesnappers.s3.us-east-2.amazonaws.com/homesnappers/wp-content/uploads/2017/03/spacer.gif"
                                        width="3"
                                        height="135"
                                        alt=""
                                        paddingTop="12px"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table
                                width="100%"
                                border="0"
                                align="center"
                                cellpadding="0"
                                cellspacing="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="top"
                                      style={{ "padding-bottom": "5px" }}
                                    >
                                      <span
                                        style={{
                                          "font-family":
                                            "Open Sans, Arial, sans-serif",
                                          "font-size": "18px",
                                          "line-height": "18px",
                                          "font-weight": "700",
                                          color: "#008bd0",
                                          "text-transform": "uppercase",
                                        }}
                                      >
                                        {authenticated?.full}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="top"
                                      style={{ "padding-bottom": "15px" }}
                                    >
                                      <span
                                        style={{
                                          "font-family":
                                            "Open Sans, Arial, sans-serif",
                                          "font-size": "16px",
                                          "line-height": "16px",
                                          "font-weight": "700",
                                          color: "#384241",
                                        }}
                                      >
                                        {authenticated?.designation}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        "font-family":
                                          "Open Sans, Arial, sans-serif",
                                        "font-size": "14px",
                                        "line-height": "14px",
                                        "font-weight": "400",
                                        color: "#647875",
                                        "padding-bottom": " 5px",
                                      }}
                                    >
                                      book@homesnappers.com
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        "font-family":
                                          "Open Sans, Arial, sans-serif",
                                        "font-size": "14px",
                                        "line-height": "14px",
                                        "font-weight": "400",
                                        color: "#647875",
                                      }}
                                    >
                                      <a
                                        href="tel"
                                        style={{
                                          color: "#647875 !important",
                                          "text-decoration": "none !important",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "#647875",
                                            "text-decoration": "none",
                                          }}
                                        >
                                          {authenticated?.phone ||
                                            "972.533.6621"}
                                        </span>
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <tr>
                                      <td
                                        style={{
                                          "font-family":
                                            "Open Sans, Arial, sans-serif",
                                          "font-size": "14px",
                                          "line-height": "14px",
                                          "font-weight": "400",
                                          color: "#647875",
                                          "padding-bottom": " 5px",
                                          "padding-top": "12px",
                                        }}
                                      >
                                        <img
                                          src="https://homesnappers.s3.us-east-2.amazonaws.com/homesnappers/wp-content/uploads/2017/03/hs-email-logo.png"
                                          alt="Home Snappers logo"
                                        ></img>
                                      </td>
                                    </tr>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          </div>
        </>
      ) : template.value === "reschedule" ? (
        <div>
          {renderLabel("To", data.client_email)}
          <Form name="dynamic_form_item" {...formItemLayoutWithOutLabel}>
            <Form.List name="names">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0
                        ? formItemLayout
                        : formItemLayoutWithOutLabel)}
                      label={index === 0 ? "CC" : ""}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        noStyle
                      >
                        <Input
                          placeholder="Please enter email"
                          style={{ width: "60%" }}
                          onChange={(e) => teamMailIdChange(e, index, "add")}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "60%" }}
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
          {data?.booking_form?.secondary_contact?.email &&
            renderCheckBox(data?.booking_form?.secondary_contact?.email)}
          {renderLabel("From", "book@homesnappers.com")}
          {renderLabel(
            "Subject",
            `Book a Shoot - ${data.address.street?.replace("null", "")}`
          )}
          <br />
          <p>Hello {data.name}</p>
          <Paragraph
            editable={{
              tooltip: "click to edit text",
              onChange: setPara15,
            }}
          >
            {para15}
          </Paragraph>
          <Paragraph
            editable={{
              tooltip: "click to edit text",
              onChange: setPara16,
            }}
          >
            {para16}
          </Paragraph>
          <Paragraph>Have a great day!</Paragraph>
          <>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td align="top">
                    <table
                      width="100%"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <tbody>
                        <tr>
                          <td width="140" align="top">
                            <img
                              src={authenticated?.headshot_url}
                              width="140"
                              height="140"
                              alt=""
                            />
                          </td>
                          <td width="30">
                            <table
                              width="1"
                              border="0"
                              align="center"
                              cellpadding="0"
                              cellspacing="0"
                            >
                              <tbody>
                                <tr>
                                  <td width="5" bgcolor="#008bd0">
                                    <img
                                      src="https://homesnappers.s3.us-east-2.amazonaws.com/homesnappers/wp-content/uploads/2017/03/spacer.gif"
                                      width="3"
                                      height="135"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table
                              width="100%"
                              border="0"
                              align="center"
                              cellpadding="0"
                              cellspacing="0"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="top"
                                    style={{ "padding-bottom": "5px" }}
                                  >
                                    <span
                                      style={{
                                        "font-family":
                                          "Open Sans, Arial, sans-serif",
                                        "font-size": "18px",
                                        "line-height": "18px",
                                        "font-weight": "700",
                                        color: "#008bd0",
                                        "text-transform": "uppercase",
                                      }}
                                    >
                                      {authenticated?.full}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="top"
                                    style={{ "padding-bottom": "15px" }}
                                  >
                                    <span
                                      style={{
                                        "font-family":
                                          "Open Sans, Arial, sans-serif",
                                        "font-size": "16px",
                                        "line-height": "16px",
                                        "font-weight": "700",
                                        color: "#384241",
                                      }}
                                    >
                                      {authenticated?.designation}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      "font-family":
                                        "Open Sans, Arial, sans-serif",
                                      "font-size": "14px",
                                      "line-height": "14px",
                                      "font-weight": "400",
                                      color: "#647875",
                                      "padding-bottom": " 5px",
                                    }}
                                  >
                                    book@homesnappers.com
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      "font-family":
                                        "Open Sans, Arial, sans-serif",
                                      "font-size": "14px",
                                      "line-height": "14px",
                                      "font-weight": "400",
                                      color: "#647875",
                                    }}
                                  >
                                    <a
                                      href="tel"
                                      style={{
                                        color: "#647875 !important",
                                        "text-decoration": "none !important",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#647875",
                                          "text-decoration": "none",
                                        }}
                                      >
                                        {authenticated?.phone}
                                      </span>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <tr>
                                    <td
                                      style={{
                                        "font-family":
                                          "Open Sans, Arial, sans-serif",
                                        "font-size": "14px",
                                        "line-height": "14px",
                                        "font-weight": "400",
                                        color: "#647875",
                                        "padding-bottom": " 5px",
                                        "padding-top": "12px",
                                      }}
                                    >
                                      <img src="https://homesnappers.s3.us-east-2.amazonaws.com/homesnappers/wp-content/uploads/2017/03/hs-email-logo.png"></img>
                                    </td>
                                  </tr>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        </div>
      ) : (
        <div>
          {renderLabel("To", data.client_email)}
          <Form name="dynamic_form_item" {...formItemLayoutWithOutLabel}>
            <Form.List name="names">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0
                        ? formItemLayout
                        : formItemLayoutWithOutLabel)}
                      label={index === 0 ? "CC" : ""}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        noStyle
                      >
                        <Input
                          placeholder="Please enter email"
                          style={{ width: "60%" }}
                          onChange={(e) => teamMailIdChange(e, index, "add")}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "60%" }}
                      icon={<PlusOutlined />}
                    >
                      Add CC: Email
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item></Form.Item>
          </Form>
          {data?.booking_form?.secondary_contact?.email &&
            renderCheckBox(data?.booking_form?.secondary_contact?.email)}
          {renderLabel("From", "book@homesnappers.com")}
          {renderLabel(
            "Subject",
            `Book a Shoot - ${data.address.street?.replace("null", "")}`
          )}
          <br />
          <p>{data.name}</p>
          <Paragraph
            editable={{
              tooltip: "click to edit text",
              onChange: setPara8,
            }}
          >
            {para8}
          </Paragraph>
          {/* <Paragraph
            editable={{
              tooltip: "click to edit text",
              onChange: setPara2,
            }}
          >
            {para2}
          </Paragraph> */}
          {/* <Paragraph
            editable={{
              tooltip: "click to edit text",
              onChange: setPara3,
            }}
          >
            {para3}
          </Paragraph> */}
          <Paragraph>Have a great day!</Paragraph>
          <>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td align="top">
                    <table
                      width="100%"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <tbody>
                        <tr>
                          <td width="140" align="top">
                            <img
                              src={authenticated?.headshot_url}
                              width="140"
                              height="140"
                              alt=""
                            />
                          </td>
                          <td width="30">
                            <table
                              width="1"
                              border="0"
                              align="center"
                              cellpadding="0"
                              cellspacing="0"
                            >
                              <tbody>
                                <tr>
                                  <td width="5" bgcolor="#008bd0">
                                    <img
                                      src="https://homesnappers.s3.us-east-2.amazonaws.com/homesnappers/wp-content/uploads/2017/03/spacer.gif"
                                      width="3"
                                      height="135"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table
                              width="100%"
                              border="0"
                              align="center"
                              cellpadding="0"
                              cellspacing="0"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="top"
                                    style={{ "padding-bottom": "5px" }}
                                  >
                                    <span
                                      style={{
                                        "font-family":
                                          "Open Sans, Arial, sans-serif",
                                        "font-size": "18px",
                                        "line-height": "18px",
                                        "font-weight": "700",
                                        color: "#008bd0",
                                        "text-transform": "uppercase",
                                      }}
                                    >
                                      {authenticated?.full}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="top"
                                    style={{ "padding-bottom": "15px" }}
                                  >
                                    <span
                                      style={{
                                        "font-family":
                                          "Open Sans, Arial, sans-serif",
                                        "font-size": "16px",
                                        "line-height": "16px",
                                        "font-weight": "700",
                                        color: "#384241",
                                      }}
                                    >
                                      {authenticated?.designation}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      "font-family":
                                        "Open Sans, Arial, sans-serif",
                                      "font-size": "14px",
                                      "line-height": "14px",
                                      "font-weight": "400",
                                      color: "#647875",
                                      "padding-bottom": " 5px",
                                    }}
                                  >
                                    book@homesnappers.com
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      "font-family":
                                        "Open Sans, Arial, sans-serif",
                                      "font-size": "14px",
                                      "line-height": "14px",
                                      "font-weight": "400",
                                      color: "#647875",
                                    }}
                                  >
                                    <a
                                      href="tel"
                                      style={{
                                        color: "#647875 !important",
                                        "text-decoration": "none !important",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#647875",
                                          "text-decoration": "none",
                                        }}
                                      >
                                        {authenticated?.phone}
                                      </span>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <tr>
                                    <td
                                      style={{
                                        "font-family":
                                          "Open Sans, Arial, sans-serif",
                                        "font-size": "14px",
                                        "line-height": "14px",
                                        "font-weight": "400",
                                        color: "#647875",
                                        "padding-bottom": " 5px",
                                        "padding-top": "12px",
                                      }}
                                    >
                                      <img src="https://homesnappers.s3.us-east-2.amazonaws.com/homesnappers/wp-content/uploads/2017/03/hs-email-logo.png"></img>
                                    </td>
                                  </tr>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  sendAppointmentConfirmation,
  fetchClientByUserId,
  createActivity,
})(AppointmentConfirmation, onSendEmail);
