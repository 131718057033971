import moment from "moment";

export const photoCodes = [
  "e5",
  "Me5",
  "Ce5",
  "e8",
  "Ae8",
  "Me8",
  "Ce8",
  "e10",
  "Me10",
  "Ce10",
  "e15",
  "Me15",
  "Ce15",
  "Me20",
  "Ce20",
  "ie5",
  "Mie5",
  "Cie5",
  "ie8",
  "Mie8",
  "Cie8",
  "ie10",
  "Mie10",
  "Cie10",
  "ie12",
  "ie15",
  "Mie15",
  "Cie15",
  "ie20",
  "Mie20",
  "Cie20",
  "ie25",
  "Mie25",
  "Cie25",
  "ie36",
  "Mie36",
  "Cie36",
  "Aie36",
  "ie42",
  "Mie42",
  "Cie42",
  "Aie42",
  "ie50",
  "Mie50",
  "Cie50",
  "Aie50",
  "ie75",
  "Mie75",
  "Cie75",
  "Aie75",
  "ie100",
  "Mie100",
  "Cie100",
  "av1",
  "5apv",
  "Mav1",
  "Cav1",
  "av3",
  "Mav3",
  "Cav3",
  "av5",
  "Mav5",
  "Cav5",
  "av10",
  "Mav10",
  "Cav10",
  "av20",
  "Mav20",
  "Cav20",
  "av30",
  "Mav30",
  "Cav30",
  "vAp",
  "MvAp",
  "CvAp",
  "vA",
  "MvA",
  "CvA",
  "AvA",
  "vWAp",
  "MvWAp",
  "CvWAp",
  "vWA",
  "MvWA",
  "CvWA",
  "vCAp",
  "vCA",
  "vCAnp",
  "MvCAp",
  "CvCAp",
  "CvCA",
  "vW",
  "MvW",
  "CvW",
  "AvW",
  "vC",
  "MvC",
  "CvC",
  "ae",
  "Mae",
  "Cae",
  "VR",
  "MVR",
  "CVR",
  "AVR",
  "T",
  "TC",
  "MT",
  "CT",
  "RF",
  "RSC",
  "RFC",
  "a25",
  "a50",
  "ad1",
  "ad2",
  "ad3",
  "ad4",
  "ad5",
  "ad6",
  "ad7",
  "ad8",
  "ad9",
  "ad10",
  "ad11",
  "ad12",
  "ad13",
  "ad14",
  "ad15",
  "ad25",
  "ad36",
  "ad50",
  "Aav1",
  "Aav3",
  "Aav5",
  "Aav10",
  "Aav15",
  "Aav20",
  "Aav25",
  "Aav30",
  "a10vA",
  "a20vA",
  "SP",
  "MSP",
  "CSP",
  "ASP",
  "SMV",
  "SMV30",
  "SMV15",
  "RSMV",
  "MSMV",
  "CSMV",
  "ASMV",
  "TRN1",
  "TRN2",
  "TRN3",
  "TRN4",
  "MTRN",
  "CTRN",
  "ATRN",
  "SAT",
  "MSAT",
  "CSAT",
  "ASAT",
  "DLX",
  "ADLX",
  "KA",
  "UC",
];

export const photographersPayCalc = (
  code,
  payMultiplier,
  sqft,
  tc,
  start,
  end,
  driveTime,
  amenities
) => {
  if (start && end) {
    var endingHours = parseInt(moment.utc(end).format("HH"));
  }
  switch (code) {
    case "DLX":
    case "ADLX":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "ad1":
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "ad2":
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "ad3":
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "ad4":
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "ad5":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "ad6":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "ad7":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "ad8":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "ad9":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "ad10":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "ad11":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "ad12":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "ad13":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "ad14":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "ad15":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "ad25":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "ad50":
      return parseFloat((35 * payMultiplier).toFixed(2));
    case "a50":
      return parseFloat((35 * payMultiplier).toFixed(2));
    case "e5":
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "Me5":
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "Ce5":
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "e8":
    case "Ae8":
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "Me8":
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "Ce8":
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "e10":
      return parseFloat((7.5 * payMultiplier).toFixed(2));
    case "Me10":
      return parseFloat((7.5 * payMultiplier).toFixed(2));
    case "Ce10":
      return parseFloat((7.5 * payMultiplier).toFixed(2));
    case "e15":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "Me15":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "Ce15":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "Me20":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "Ce20":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "ie5":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "Mie5":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "Cie5":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "ie10":
    case "ie8":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "Mie10":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "Cie10":
      return parseFloat((10 * payMultiplier).toFixed(2));
    case "ie15":
    case "ie12":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "Mie15":
    case "MF12":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "Cie15":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "ie20":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "Mie20":
    case "MF20":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "Cie20":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "ie25":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "Mie25":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "Cie25":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "ie36":
    case "ad36":
      return parseFloat((25 * payMultiplier).toFixed(2));
    case "Mie36":
      return parseFloat((25 * payMultiplier).toFixed(2));
    case "Cie36":
      return parseFloat((25 * payMultiplier).toFixed(2));
    case "ie42":
    case "Mie42":
    case "Cie42":
    case "Aie42":
      return parseFloat(
        (
          (moment(start).isAfter("2023-04-01") ? 30 : 25) * payMultiplier
        ).toFixed(2)
      );
    case "ie50":
    case "Mie50":
    case "Cie50":
    case "Aie50":
    case "a5aV":
    case "Ma5aV":
    case "Ca5aV":
      return parseFloat((35 * payMultiplier).toFixed(2));
    case "ie75":
    case "Mie75":
    case "Cie75":
    case "Aie75":
      return parseFloat((45 * payMultiplier).toFixed(2));
    case "ie100":
    case "Mie100":
    case "Cie100":
      return parseFloat((55 * payMultiplier).toFixed(2));
    case "av1":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "av25":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "Mav1":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "Cav1":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "av3":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "Mav3":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "Cav3":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "av5":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "Mav5":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "Cav5":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "av10":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "Mav10":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "Cav10":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "av20":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "Mav20":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "Cav20":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "av30":
      return parseFloat((40 * payMultiplier).toFixed(2));
    case "Mav30":
      return parseFloat((40 * payMultiplier).toFixed(2));
    case "Cav30":
      return parseFloat((40 * payMultiplier).toFixed(2));
    case "vAp":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "MvAp":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "CvAp":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "vWAp":
      return parseFloat((45 * payMultiplier).toFixed(2));
    case "MvWAp":
      return parseFloat((45 * payMultiplier).toFixed(2));
    case "CvWAp":
      return parseFloat((45 * payMultiplier).toFixed(2));
    case "vA":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "MvA":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "CvA":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "AvA":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "vWA":
      return parseFloat((50 * payMultiplier).toFixed(2));
    case "MvWA":
      return parseFloat((50 * payMultiplier).toFixed(2));
    case "CvWA":
      return parseFloat((50 * payMultiplier).toFixed(2));
    case "vCAp":
    case "vCA":
    case "vCAnp":
      return parseFloat((70 * payMultiplier).toFixed(2));
    case "MvCAp":
      return parseFloat((70 * payMultiplier).toFixed(2));
    case "CvCAp":
      return parseFloat((70 * payMultiplier).toFixed(2));
    case "CvCA":
      return parseFloat((70 * payMultiplier).toFixed(2));
    case "vW":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "MvW":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "CvW":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "AvW":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "vC":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "MvC":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "CvC":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "KA":
      return parseFloat((400 * payMultiplier).toFixed(2));
    case "5apv":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "a10vA":
      return parseFloat((40 * payMultiplier).toFixed(2));
    case "a20vA":
      return parseFloat((50 * payMultiplier).toFixed(2));
    case "ae":
      return parseFloat((70 * payMultiplier).toFixed(2));
    case "Mae":
      return parseFloat((70 * payMultiplier).toFixed(2));
    case "Cae":
      return parseFloat((70 * payMultiplier).toFixed(2));
    case "Aav1":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "Aav3":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "Aav5":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "Aav10":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "Aav15":
    case "av15":
      return parseFloat((25 * payMultiplier).toFixed(2));
    case "Aav20":
      return parseFloat((30 * payMultiplier).toFixed(2));
    case "Aav25":
      return parseFloat((35 * payMultiplier).toFixed(2));
    case "Aav30":
      return parseFloat((40 * payMultiplier).toFixed(2));
    case "TRN1":
      return parseFloat(10);
    case "TRN2":
      return parseFloat(20);
    case "TRN3":
      return parseFloat(30);
    case "TRN4":
      return parseFloat(40);
    case "MTRN":
    case "CTRN":
    case "ATRN":
      return parseFloat(10);
    case "SMV30":
    case "SMV":
    case "CSMV":
    case "ASMV":
    case "MSMV":
      return parseFloat((15 * payMultiplier).toFixed(2));
    case "SMV15":
      return parseFloat((12.5 * payMultiplier).toFixed(2));
    case "SAT":
    case "CSAT":
    case "ASAT":
    case "MSAT":
      // Check if date is March 1st, 2025 or later
      if (moment(start).isSameOrAfter("2025-03-01")) {
        return 15.0;
      }
      return parseFloat((5 * payMultiplier).toFixed(2));
    case "VR":
    case "MVR":
    case "CVR":
    case "AVR":
      const dollHousePay = (
        (((sqft / 250) * 4.5 + 30) / 60) *
        45 *
        payMultiplier
      ).toFixed(2);
      return parseFloat(dollHousePay);
    case "T":
    case "MT":
    case "CT":
      if (endingHours === 19 && endingHours < 20) {
        return parseFloat((20 * payMultiplier).toFixed(2));
      } else if (endingHours >= 20) {
        return parseFloat((30 * payMultiplier).toFixed(2));
      } else {
        return parseFloat((10 * payMultiplier).toFixed(2));
      }
    case "TC":
      if (moment(start).isAfter("2022-06-18")) {
        const tripCharge = parseFloat(tc);
        if (tripCharge >= 50) {
          // Pay the photographer 75% of the trip charge
          const photographer_tc_pay = (tripCharge / 10) * 7.5;
          return parseFloat(photographer_tc_pay.toFixed(2));
        } else {
          return 0;
        }
      } else {
        if (driveTime > 49 && moment(start).isBefore("2022-04-04")) {
          const driveTimeValue = parseFloat(driveTime);
          const tripCharge = parseFloat(tc);
          const photographer_trip_charge =
            Math.ceil((driveTimeValue - 49) / 5) * 10;
          const new_trip_charge =
            parseFloat(photographer_trip_charge + tripCharge) / 2;
          const calculated_trip_charge = new_trip_charge * payMultiplier;
          const photographer_tc_pay =
            calculated_trip_charge > 0 ? (calculated_trip_charge / 10) * 10 : 0;
          return parseFloat(photographer_tc_pay.toFixed(2));
        } else {
          return 0;
        }
      }
    case "RF":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "RSC":
      return parseFloat((20 * payMultiplier).toFixed(2));
    case "UC":
      return 10;
    case "RFC":
      return 50;
    case "SP":
    case "MSP":
    case "CSP":
    case "ASP":
      return parseFloat((25 * payMultiplier).toFixed(2));

    default:
      return 0;
  }
};

export const updatePhotographerCalc = (
  invoices,
  payMultiplier,
  sqft,
  tc,
  start,
  end,
  driveTime,
  props
) => {
  var updatedInv = [];
  invoices.forEach(async (inv) => {
    var total_pay = 0.0;
    if (inv.products) {
      inv.products.map((prod) => {
        if (photoCodes.includes(prod.code)) {
          const pay = photographersPayCalc(
            prod.code,
            payMultiplier,
            sqft,
            tc,
            start,
            end,
            driveTime,
            prod.amenities
          );
          prod.photographer_pay = pay;
          total_pay += pay;
          inv.photographer_total_pay = total_pay;
        }
        return prod;
      });
      updatedInv.push({
        products: [...inv.products],
        photographer_total_pay: inv.photographer_total_pay,
      });
      const res = await props.updateInvoice(inv._id, {
        products: [...inv.products],
        photographer_total_pay: inv.photographer_total_pay,
      });
      if (res.error) {
        console.error(
          "Error occured while updating the photographer's pay ",
          res.error
        );
      }
    }
  });
};
